  
$(document).ready( function() { 


  // if ( ($('body').attr('data-controller') === 'two_factor_authentications' &&  $('body').attr('data-action') === 'create') || 
  //    ($('body').attr('data-controller') === 'users/otp/sessions' &&  $('body').attr('data-action') === 'new') )
  //  {


      //console.log("otp_digit_field_controller.js loaded");

      //static targets = ['mainField']
      var target = $('.otp_input_shit');
      
      //static values = { codeLength: String }
      var codeLength = $('#otp-digit-field').data('otp-digit-field-code-length-value');

      //console.log("codeLength: " + codeLength)


      $(document).on('keydown', '.otp_input_shit', function (e) {

        if (!_isValidOtpField(e.key)) {
          e.preventDefault();
        }
      });

      $(document).on('input', '.otp_input_shit', function (e) {

        const digitValue = e.target.value;
        if (digitValue == null) { return; }

        if(('0' <= digitValue && digitValue <= '9') || ('a' <= digitValue && digitValue <= 'z')) {
          const next = document.body.querySelector(`input#${e.currentTarget.dataset.next}`)

          if(next !== null) {
            next.focus()
          }
        }

        _updateMainField()
      });

      $(document).on('keyup', '.otp_input_shit', function (e) {
        //console.log("3");
        if(e.key === 'Backspace' || e.key === 'ArrowLeft') {

          //console.log("3a");

          const prev = document.body.querySelector(`input#${e.currentTarget.dataset.previous}`)

          if(prev !== null) {
            prev.focus()
          }
        } else if(e.key === 'ArrowRight') {

          const next = document.body.querySelector(`input#${e.currentTarget.dataset.next}`)

          if(next !== null) {
            next.focus()
          }
        } else if(e.key === 'Enter' && _allFieldsAreFilled()) {
          
          $( "form" ).submit();
        }
      });

      window._updateMainField = function() {
        let otpCode = ''
        for (var i = 1; i < (parseInt(codeLength) + 1); i++) {
          otpCode += document.body.querySelector(`input#digit-${i}`).value;
        }

        //$('#otp-code-field').val(otpCode);
        document.getElementById('otp-code-field').value = otpCode

        //this.mainFieldTarget.value = otpCode
      }

      window._isValidOtpField = function(key){
        return (key === 'Backspace') ||
                (key === 'ArrowLeft') ||
                (key === 'ArrowRight') ||
                ('0' <= key && key <= '9') ||
                ('a' <= key && key <= 'z');
      }

      window._allFieldsAreFilled = function() {
        return $('.otp_input_shit').length === parseInt(codeLength);
      }

   // }

});