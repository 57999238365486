import consumer from "./consumer"

//$(document).on('turbolinks:load', function() {

  $(document).ready(function() {

    // NOG INSTELLEN
    if ($('body').attr('data-controller') === 'roomss' ) {

      document.addEventListener("turbolinks:load", () => {

          if (this.subscription) {
              consumer.subscriptions.remove(this.subscription);
              // console.log("unsubing");
          }

          if (document.getElementById('room-id')) {

            var messagesNews = document.getElementById('messages-news');
            const messageContainer = document.getElementById('messages');
            var isScrolling = false;
            var timer = null;

            if (document.getElementById("messages")) {      

                document.getElementById("messages").addEventListener('scroll', function() {

                  // if ((messagesNews.innerHeight + messagesNews.scrollY) >= messagesNews.offsetHeight) {
                  if (messageContainer.scrollHeight - messageContainer.scrollTop === messageContainer.clientHeight) {
                    //console.log("bottom reached!");
                    $(messagesNews).hide();
                  }

                  if (timer !== null) {
                    clearTimeout(timer);
                    isScrolling = true;
                    //console.log("aan het scrollen");
                  }

                  timer = setTimeout(function() {
                    isScrolling = false;
                    //console.log("klaar met scrollen");
                  }, 250);

                }, false);
            }

            const room_element = document.getElementById('room-id');
            const room_id = room_element.getAttribute('data-room-id');
            // console.log("room_channel loaded: " + room_id);

            var subscription = consumer.subscriptions.create({channel: "RoomChannel", room_id:room_id}, {
                connected() {
                    console.log("RoomChannel connected");
                    
                    if ( document.getElementById('messages') ) {
                      const messageContainer = document.getElementById('messages');
                      $(messageContainer).animate({scrollTop: messageContainer.scrollHeight}, 'slow');
                    }
              
                },

                disconnected() {
                   // Called when the subscription has been terminated by the server
                   console.log("RoomChannel disconnected");
                },

                received(data) {
                  //console.log("subscription received");
                  //console.log(data);
                  
                  messageContainer.innerHTML = messageContainer.innerHTML + data.html;

                  $('#message_content').val('');
                  
                  if (!isScrolling) {                    
                    
                    messageContainer.scrollTop = messageContainer.scrollHeight * 10;

                    //$(messageContainer).animate({scrollTop: messageContainer.scrollHeight}, 'slow');
                  } else {

                    $(messagesNews).show();
                  }

                  checkCodeMirrors_chat();

                  Object.keys(codeMirror_chat_ids).forEach(function (key) { 
                    
                      $(codeMirror_chat_XML[codeMirror_chat_ids[key]].getWrapperElement()).show();
                      codeMirror_chat_XML[codeMirror_chat_ids[key]].setSize("100%", "80px");
                      //codeMirror_chat_XML[codeMirror_chat_ids[key]].refresh();
                  })

                  $('#message_content').height('');
                }
            });

            this.subscription = subscription;
          }
      });
  }
  });

