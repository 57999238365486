import consumer from "./consumer"


$(document).ready(function() {

  if ( ($('body').attr('data-controller') === 'lsp')  && !( $('body').attr('data-action') === "aortatokens") ){

    consumer.subscriptions.create("ValidateResponseChannel", {
      connected() {
         // Called when the subscription is ready for use on the server
         console.log("ValidateResponseChannel connected");
      },

      disconnected() {
         // Called when the subscription has been terminated by the server
         console.log("ValidateResponseChannel disconnected");
      },

      received(data) {
        // Called when there's incoming data on the websocket for this channel
        $('#response_validation').html(data.validatedhtml);
        $('#btn_view_xml_errors').show().text("Zie validatie").removeClass('btn-success').addClass('btn-warning');
        $('#loading-span-response').hide();
      }
    });

  }

});
