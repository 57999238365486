import consumer from "./consumer"


$(document).ready(function() {


// NOG INSTELLEN
  if ($('body').attr('data-controller') === 'ketentests' ) {


   document.addEventListener("turbolinks:load", () => {

      //console.log(consumer);

      if (this.subscription) {
         consumer.subscriptions.remove(this.subscription);
         //console.log("unsubing KetentestTestscriptChannel");
      }

      if (document.getElementById('ketentest_start_index')) {

        //var ketentest_testscript_id = 2;

         // consumer.subscriptions.create({channel:"KetentestTestscriptChannel",ketentest_testscript_id: ketentest_testscript_id }, {
            
         consumer.subscriptions.create("KetentestTestscriptChannel", {
            connected() {
               // Called when the subscription is ready for use on the server
               console.log("KetentestTestscriptChannel connected");
            },

            disconnected() {
               // Called when the subscription has been terminated by the server
               console.log("KetentestTestscriptChannel disconnected");
            },

            received(data) {
            
               //console.log("subscription received");
               //console.log(data);
               var testscriptContainer = document.getElementById(String(data.ketentest_testscript_id));
               testscriptContainer.innerHTML = data.html;

               //console.log(testscriptContainer);
                
               
               //$('#message_content').val('');
               if (data.percent == 100 ) {
                  $('#ketentest_box-' + data.ketentestid).removeClass('kt_box_not_finished').addClass('kt_box_finished');
               } else {
                  $('#ketentest_box-' + data.ketentestid).removeClass('kt_box_finished').addClass('kt_box_not_finished');
               }

               $('#ketentest-box-percent-' + data.ketentestid + ' h4').html(data.percent + "<span>%</span>");
               $('.ketentest-percent h2').html(data.percent + "<span>%</span>");
               $('circle#ketentest-circle-stroke-dasharray').css("stroke-dashoffset", 'calc(251 - (251 * ' + data.percent + ') / 100)');
               $("circle#ketentest-circle-stroke-dasharray-" + data.ketentestid).css("stroke-dashoffset", "calc(251 - (251 * " + data.percent + ") / 100)");
               Dropzone.discover();

            }
        });



      }
   });
}
});


