import consumer from "./consumer"

$(document).ready(function() {


  if ($('body').attr('data-controller') === 'chats') {

  document.addEventListener("turbolinks:load", () => {

    //console.log(this);
    //console.log(consumer);

    if (this.chatchannel) {
      consumer.subscriptions.remove(this.chatchannel);
      //console.log("unsubing chatchannel");
    }

    if (document.getElementById('rooms')) {

      //var rooms = document.getElementById('rooms');

      var chatchannel = consumer.subscriptions.create("ChatChannel", {
        connected() {
            // Called when the subscription is ready for use on the server
            console.log("ChatChannel connected");
         },

         disconnected() {
            // Called when the subscription has been terminated by the server
            console.log("ChatChannel disconnected");
         },

        received(data) {
          //console.log(data);
          // Called when there's incoming data on the websocket for this channel
          const chatContainer = document.getElementById('rooms');
          chatContainer.innerHTML = '';
          chatContainer.innerHTML = chatContainer.innerHTML + data.html;

        }
      });

      this.chatchannel = chatchannel;
    }

  });
}

});