import consumer from "./consumer"




$(document).ready(function() {


  if ($('body').attr('data-controller') === 'ketentests') {
  
    //console.log("ketentest_room_channel.js");  
      document.addEventListener("turbolinks:load", () => {

        //console.log(consumer);

        if (this.subscription) {
          consumer.subscriptions.remove(this.subscription);
          // console.log("unsubing");
        }

                
        //var isScrolling = false;


        consumer.subscriptions.create("KetentestRoomChannel", {
          connected() {
            // Called when the subscription is ready for use on the server
            console.log("KetentestRoomChannel connected");
         },

         disconnected() {
            // Called when the subscription has been terminated by the server
            console.log("KetentestRoomChannel disconnected");
         },

          received(data) {
            // Called when there's incoming data on the websocket for this channel

              //console.log(data);

              const messageContainer = document.getElementById('ketentest-chat-messages');
              var messagesNews = document.getElementById('ketentest-chat-messages-news');
              var ketentestChatRoom = document.getElementById('ketentestchatroom');

              messageContainer.innerHTML = messageContainer.innerHTML + data.html;

              $('#message_content').val('');
              $('#message_content').css("height","auto");

              checkCodeMirrors_chat();

              Object.keys(codeMirror_chat_ids).forEach(function (key) { 
                  
                  //console.log(key);
                  //console.log( codeMirror_chat_ids[key] );

                  $(codeMirror_chat_XML[codeMirror_chat_ids[key]].getWrapperElement()).show();
                  codeMirror_chat_XML[codeMirror_chat_ids[key]].setSize("100%", "100px");
                  //codeMirror_chat_XML[codeMirror_chat_ids[key]].refresh();
              });


              //console.log("isscrolling?: " + window.sessionStorage.getItem('isScrolling'));

              if (window.sessionStorage.getItem('isScrolling')) {                    
                        
                ketentestChatRoom.scrollTop = ketentestChatRoom.scrollHeight * 10;

                //$(messageContainer).animate({scrollTop: messageContainer.scrollHeight}, 'slow');
              } else {

                $(messagesNews).show();
              }




          }
        });

      });

    }

});
