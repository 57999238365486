import consumer from "./consumer"


if ($('body').attr('data-controller') === 'users' ) {

  consumer.subscriptions.create("UsersChannel", {

    connected() {
        // Called when the subscription is ready for use on the server
        console.log("UsersChannel connected");
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
        console.log("UsersChannel disconnected");
      },

    received(data) {
     
          // console.log(data);

          var trUserElem = $('tr[data-userid=' + data.userid + ']').html(data.html);

    }
  });

}