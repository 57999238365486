import consumer from "./consumer"

// NOG INSTELLEN
$(document).ready(function() {


  if ($('body').attr('data-controller') === 'acceptatie_tests') {
      
            
      consumer.subscriptions.create("AcceptatieTestscriptChannel", {
         connected() {
            // Called when the subscription is ready for use on the server
            console.log("AcceptatieTestscriptChannel connected");
         },

         disconnected() {
            // Called when the subscription has been terminated by the server
            console.log("AcceptatieTestscriptChannel disconnected");
         },

         received(data) {
         
            //console.log("subscription received");
            //console.log(data);
            var testscriptContainer = document.getElementById(String(data.acceptatie_testscript_id));
            testscriptContainer.innerHTML = data.html;

            //console.log(testscriptContainer);
             
            
            //$('#message_content').val('');
            if (data.percent == 100 ) {
               $('#acceptatie_box-' + data.acceptatieid).removeClass('acc_box_not_finished').addClass('acc_box_finished');
            } else {
               $('#acceptatie_box-' + data.acceptatieid).removeClass('acc_box_finished').addClass('acc_box_not_finished');
            }

            $('#acceptatie-box-percent-' + data.acceptatieid + ' h4').html(data.percent + "<span>%</span>");
            $('.acceptatie-percent h2').html(data.percent + "<span>%</span>");
            $('circle#acceptatie-circle-stroke-dasharray').css("stroke-dashoffset", 'calc(251 - (251 * ' + data.percent + ') / 100)');
            $("circle#acceptatie-circle-stroke-dasharray-" + data.acceptatieid).css("stroke-dashoffset", "calc(251 - (251 * " + data.percent + ") / 100)");
            Dropzone.discover();

         }
     });

}

});