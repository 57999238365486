import consumer from "./consumer"

$(document).ready(function() {


  if ( ($('body').attr('data-controller') === 'lsp') && !( $('body').attr('data-action') === "aortatokens" ) ) {

    consumer.subscriptions.create("ValidateRequestChannel", {
      connected() {
         // Called when the subscription is ready for use on the server
         console.log("ValidateRequestChannel connected");
      },

      disconnected() {
         // Called when the subscription has been terminated by the server
         console.log("ValidateRequestChannel disconnected");
      },

      received(data) {
        // Called when there's incoming data on the websocket for this channel
        //console.log("request_validation");
        // console.log(data);
        // console.log(data.validatedHtmlRequest);
        
        $('#request_validation').html(data.validatedHtmlRequest);
        $('#btn_view_xml_errors_request').show().text("Zie validatie").removeClass('btn-success');
        $('#loading-span-request').hide();

      }
    });

   }
});