// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require.context('../images', true)
//require.context('../src', true,  /\.js$/)
//require.context('../stylesheets', true,  /\.css$/)

import "src/dropzone.min"
import "src/codemirror"
import "src/main"
import "src/formatting"
import "src/loading-bar.min"
import "src/xml"
import "src/XmlBeautify"
import "src/otp_digit_field_controller"
import "src/vkbeautify"

import "stylesheets/codemirror"
import "stylesheets/loading-bar.min"
import "stylesheets/monokai"
import "stylesheets/elegant"
import "stylesheets/dropzone.min"
import "stylesheets/ketentest"
import "stylesheets/acceptatie"

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
//import "controllers"


Rails.start()
Turbolinks.start()
ActiveStorage.start()

