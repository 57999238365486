
//console.log("main.js loaded");

Dropzone.autoDiscover = false;

var responseBodyRaw = "";
var responseBodyBeautified = "";
var requestBodyRaw = "";
var requestBodyBeautified = "";

window.XmlBeautify = require("./XmlBeautify.js");
const { DOMParser } = require('xmldom');// When used in a node.js environment, DOMParser is needed.


function isJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

document.addEventListener("DOMContentLoaded", function(){
  // make it as accordion for smaller screens
  if (window.innerWidth < 992) {

    // close all inner dropdowns when parent is closed
    document.querySelectorAll('.navbar .dropdown').forEach(function(everydropdown){
      everydropdown.addEventListener('hidden.bs.dropdown', function () {
        // after dropdown is hidden, then find all submenus
          this.querySelectorAll('.submenu').forEach(function(everysubmenu){
            // hide every submenu as well
            everysubmenu.style.display = 'none';
          });
      })
    });

    document.querySelectorAll('.dropdown-menu a').forEach(function(element){
      element.addEventListener('click', function (e) {
          let nextEl = this.nextElementSibling;
          if(nextEl && nextEl.classList.contains('submenu')) {  
            // prevent opening link if link needs to open dropdown
            e.preventDefault();
            if(nextEl.style.display == 'block'){
              nextEl.style.display = 'none';
            } else {
              nextEl.style.display = 'block';
            }

          }
      });
    })
  }
  // end if innerWidth
}); 
// DOMContentLoaded  end

  // (window.sessionStorage.getItem('userSignedOutCounter') === "NaN") ? window.sessionStorage.setItem('userSignedOutCounter', 0) : console.log("Welcome visiter");
  //window.sessionStorage.setItem('userSignedOutCounter', 0) 
  var userActive = false;
  //var userActiveTimeMs = gon.user_timeout * 1000;
  var userActiveTimeMs = 30 * 1000;
  var userInactive = false;
  var idleTime = 0;
  var msActiveTime = 5000; // uziCard check loop, 5000 is OK

  $(document).on('click', '.btn_download', function(e) {
      e.preventDefault();
      e.stopPropagation();

      var varType = "Response";
      var data = "";
      if ($(this).hasClass('request')) {
        varType = "Request";
        data = requestBodyRaw;
      } else {
        data = responseBodyRaw;
      }
      //download( varType + "_" + window.sessionStorage.getItem("bsn") + ".XML", $(this).parent().find('textarea').text() );
      download( varType + "_" + Date.now() + ".XML", data );
  });

 window.startZorgId = function(){
    $.ajax({
      url: "/initialize_session", 
      type: "GET",
      beforeSend: function() {
        console.log('Zorg-ID initializing session');
      },
      success: function(data, textStatus, jqXHR) {
        console.log("Zorg-Id started");
      },
      error: function(jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);        
      }
    });
  }
  
  $(document).on('click', '.dashboard_download', function(e) {
      e.preventDefault();
      e.stopPropagation();

      _this = $(this);

      $.ajax({
          url: "/dashboard_download/" + $(_this).closest('tr').attr('id'), 
          type: "GET",
          beforeSend: function() {                   
            
          },
          success: function(data, textStatus, jqXHR)
          {
            console.log(data);
            download( data.interactie + "-" + data.messageid + ".XML", data.xml );
          },
          error: function(jqXHR, textStatus, errorThrown)
          {

          }
      });
      
      
  });

  window.autoExpand = function (field) {
    if (!field) {
      return false;
    }

    field.style.height = 'inherit';
    var computed = window.getComputedStyle(field);
    var height = parseInt(computed.getPropertyValue('border-top-width'), 10)
                 + parseInt(computed.getPropertyValue('padding-top'), 10)
                 + field.scrollHeight
                 + parseInt(computed.getPropertyValue('padding-bottom'), 10)
                 + parseInt(computed.getPropertyValue('border-bottom-width'), 10);
    field.style.height = height + 'px';
  };


  document.addEventListener('input', function (event) {
    if (event.target.tagName.toLowerCase() !== 'textarea') return;
    autoExpand(event.target);

  }, false);


  $(document).on('input', 'textarea', function () {
    $(this).outerHeight(38).outerHeight(this.scrollHeight); // 38 or '1em' -min-height
  }); 


  document.addEventListener('textarea', function (event) {
    if (event.target.tagName.toLowerCase() !== 'textarea') return;
    autoExpand(event.target);
  }, false);



  $(document).on('click', '.check_uzipas_connection', function(e) {
    e.stopPropagation();
    e.preventDefault();
    initializeSessionThenClick( $(this).parent() );
  });


   window.initializeSessionThenClick = function(e) {

    var btntext = $('#formButton').children().text();
    
    $('#formButton').prop('disabled', true).children().text('Zorg-ID controleren');
    $('#loader').show();

    if ( (window.sessionStorage.getItem('detachedopen') == 'false')) {    
        // if ( ($('#status').text().indexOf("Closed") >= 0) || ($('#status').text().indexOf("Closed") == -1 ) )  {
      var status = window.sessionStorage.getItem('sessionStatus');

      if ( (status.indexOf("Closed") >= 0) || (status.indexOf("Closed") == -1) )  {     
        
          $.ajax({
                url: "/initialize_session", 
                type: "GET",
                beforeSend: function() {                   
                  $('#loaderUziPas').show();
                  $('#formButton').children().text('Zorg-ID initializing session');           
                },
                success: function(data, textStatus, jqXHR)
                {
                  location.href = "ZorgID://s?n=" + data.nonce + "&e=ZD6";    
                  $.ajax({
                        url: "/open_session", 
                        type: "GET",
                        beforeSend: function() {
                          $('#formButton').children().text('Zorg-ID opening session');
                        },
                        success: function(data, textStatus, jqXHR)
                        {
                           console.log("Success");
                           e.submit();
                        },
                        complete: function(){
                          $('#loaderUziPas').hide();
                          $('#formButton').children().text(btntext);
                        }
                    });
                },
                error: function(jqXHR, textStatus, errorThrown)
                {
                    $('#id_card').css( "color", "red" );
                    $('#login_zorgid_btn').css("display", "block"); 
                    $('#close_session_btn').css("display", "none");
                    $('#uzipas_btn').css("display", "none");

                    $('#loaderUziPas').hide();
                    $('#loader').hide();
                }
            });

      } else {
          location.href = "ZorgID://s?n=<%= @nonce %>&e=ZD6";    
          $.ajax({
            url: "/open_session", 
            type: "GET",
            success: function(data, textStatus, jqXHR)
            {
              console.log("else trigger")
              e.submit();
            },
            complete: function(){
              $('#loaderUziPas').hide();
            }
        });
      }       

    } else {
      console.log("else else trigger")

      e.submit();
    }

    return false; 
  }; // function initializeSessionThenClick



  window.initializeSessionandRunFunction = function(funktie, useZorgIdClient = true) {

    if ( (document.getElementById('inschrijftoken_id') != null) && (document.getElementById('inschrijftoken_id').value.length == 0 )  )  {
      $('#formButton').prop('disabled', true).children().text('Kies eerst een BSN - inschrijftoken').css("color", "red");
      return false;
    } 

    $('#formButton').prop('disabled', true).children().text('Verstuur').css("color", "");
    $('#loader').show();

    if (useZorgIdClient) {

      if ( window.sessionStorage.getItem('usepas') == "false") { 
         $.ajax({
            url: "initialize_session", 
            type: "GET",
            beforeSend: function() {
            
              $('#formButton').children().text('Zorg-ID initializing session');
            
            },
            success: function(data, textStatus, jqXHR) {

              console.log("success");
              funktie();

            },
            error: function(jqXHR, textStatus, errorThrown) {

              console.log(textStatus);

              $('#formButton').children().text("Verstuur");
              $('#loader').hide();
            
            }
        });

      } else {


          if ( (window.sessionStorage.getItem('detachedopen') == 'false')) {    
              // if ( ($('#status').text().indexOf("Closed") >= 0) || ($('#status').text().indexOf("Closed") == -1 ) )  {
            var status = window.sessionStorage.getItem('sessionStatus');

              if ( (status.indexOf("Closed") >= 0) || (status.indexOf("Closed") == -1) )  {     
                
                  $.ajax({
                        url: "/initialize_session", 
                        type: "GET",
                        beforeSend: function() {                   
                          $('#loaderUziPas').show();
                          $('#formButton').children().text('Zorg-ID initializing session');           
                        },
                        success: function(data, textStatus, jqXHR)
                        {
                          console.log("ZorgID://s?n=" + data.nonce + "&e=ZD6");
                          location.href = "ZorgID://s?n=" + data.nonce + "&e=ZD6";   
                          $.ajax({
                                url: "/open_session", 
                                type: "GET",
                                beforeSend: function() {
                                  $('#formButton').children().text('Zorg-ID opening session');
                                },
                                success: function(data, textStatus, jqXHR)
                                {
                                    funktie();
                                },
                                error: function(jqXHR, textStatus, errorThrown)
                                {
                                    
                                },
                                complete: function(){
                                  $('#loaderUziPas').hide();
                                  $('#formButton').children().text("Versturen");
                                }
                            });
                        },
                        error: function(jqXHR, textStatus, errorThrown)
                        {
                          
                            $('#id_card').css( "color", "red" );
                            $('#login_zorgid_btn').css("display", "block"); 
                            $('#close_session_btn').css("display", "none");
                            $('#uzipas_btn').css("display", "none");

                            $('#loaderUziPas').hide();
                            $('#loader').hide();
                        }
                    });

              } else {
                  
                  console.log("ZorgID://s?n=<%= @nonce %>&e=ZD6");
                  location.href = "ZorgID://s?n=<%= @nonce %>&e=ZD6";   
                  $.ajax({
                    url: "/open_session", 
                    type: "GET",
                    beforeSend: function(request) {
                      
                    },
                    success: function(data, textStatus, jqXHR)
                    {
                        //console.log("Success open_session: " + textStatus); 
                        funktie();
                    },
                    error: function(jqXHR, textStatus, errorThrown)
                    {
                        
                    },
                    complete: function(){
                      $('#loaderUziPas').hide();
                    }
                });
              }       

          } else {
            //console.log("formButton Else versturen verstuurdSpoedverwijzing");
            funktie();
          }

      }

    } else {

      funktie();
      
    }

    return false; 
  }; // function initializeSessionandRunFunction


  $(document).on('click', '#zoek_in_zab_btn', function () {
    $("#zoek_in_zab").toggle( "slow");
  });

  $(document).on('change', '#omgeving', function () {
    varOmgeving = document.getElementById("omgeving").value;
  });

  
  
  

  $(document).on("keyup change", "#custom_Given", function(e) {

  // $('#custom_Given').bind('keyup change', function(e) {
    
      var VAL = this.value;
      var initialen = new RegExp('^([A-Z]\.)+$');

      if (!initialen.test(VAL)) {
        $('#formButton').prop('disabled', false);
        $('#custom_Given').css('border-color', 'red');
      } else {
        $('#formButton').prop('disabled', true);
        $('#custom_Given').css('border-color', '');
      }
          
  });
  

   window.nWin = function() {
    var w = window.open();
    var html = $("#MyModalHTMLBody").html();

      $(w.document.body).html(html);
  }


$(document).on('change', '.user_checkbox_tester', function() {
   var userId = $(this).closest('tr').data('userid')
   var isChecked = $(this).is(':checked');

   $.ajax({
      type: "POST",
      url: "user/user_tester/" + userId,
      data: {"user_tester": isChecked},
      dataType: "json",
      // contentType: "application/json; charset=utf-8",
      beforeSend: function(request){
         request.setRequestHeader( "X-CSRF-Token", document.getElementsByName("csrf-token")[0].content);
      },
      success: function(data, status){   
      },
      error: function(errMsg) {
      },
      complete: function(jqXHR,status){
      }
   });

});


$(document).on('change', '.checkbox_reset_locked', function() {
   var userId = $(this).closest('tr').data('userid')
   var isChecked = $(this).is(':checked');

   if (!isChecked) {

      $.ajax({
         type: "POST",
         url: "user/reset_locked/" + userId,
         data: {"reset_locked": true},
         dataType: "json",
         // contentType: "application/json; charset=utf-8",
         beforeSend: function(request){
            request.setRequestHeader( "X-CSRF-Token", document.getElementsByName("csrf-token")[0].content);
         },
         success: function(data, status){   
         },
         error: function(errMsg) {
         },
         complete: function(jqXHR,status){
         }
      });

   }
});

$(document).on('click', '.btn-versturen-ontvangen', function() {
  var varVersturen = false;
  
  if ( !$(this).hasClass('clicked') ) {
    $('.btn-versturen-ontvangen').removeClass('clicked');
    $(this).addClass('clicked');
  }  

  if ( $(this).is("#btn-versturen1") ) {
    console.log("is btn-versturen1");
    varVersturen = true;
  }

  $.ajax({
    type: "POST",
    url: "/user/setversturenofontvangen",
    data: JSON.stringify({"versturen": varVersturen}),
    contentType: "application/json; charset=utf-8",
    dataType: "json",
    beforeSend: function(request){
      request.setRequestHeader("X-CSRF-Token", document.getElementsByName("csrf-token")[0].content);
    },
    success: function(data, status){
      console.log(data);
    },
    error: function(errMsg) {
    },
    complete: function(jqXHR,status){
    }
  });
});

$(document).on('change', '.user_approved', function() {

   var userId = $(this).closest('tr').data('userid')
   var approved = false;

   if ($(this).is(':checked')) {
      approved = true;
   }

   $.ajax({
      type: "POST",
      url: "user/approved/" + userId,
      data: {"approved": approved},
      dataType: "json",
      // contentType: "application/json; charset=utf-8",
      beforeSend: function(request){
         request.setRequestHeader( "X-CSRF-Token", document.getElementsByName("csrf-token")[0].content);
      },
      success: function(data, status){   
      },
      error: function(errMsg) {
      },
      complete: function(jqXHR,status){
      }
   });
});

// ACCEPTATIES
$(document).on('click', '.tr_start_acceptatie', function(){
    var id = $(this).data('id');
    console.log("Acceptatie_ID: " + id );
    $(document).find('.acc_box_selected').removeClass('acc_box_selected');
    $(this).addClass('acc_box_selected');
    //, :locals => {:interactie => @partial, :title => @title }

    $('.footer').hide();
    acceptatie_start_testscript(id);
  });


function acceptatie_start_testscript(id){
    $.ajax({
        type: "GET",
        url: "/acceptatie_start_testscript/" + id,
        // data: JSON.stringify(data),
        // contentType: "application/json; charset=utf-8",
        dataType: "json",
        beforeSend: function(request){
          $('#spinner').show();
          $('#acceptatie_start_index').css('height','13.8vh');
        },
        success: function(data, status){
          //console.log("success ketentest_start_testscript");
          //console.log(data);
          $('#acc_start_testscript').html(data.testscript);
          $('#acceptatie_testscript_chat').html(data.chatroom);
        }, 
        error : function(errMsg, textStatus, errorThrown) {
          console.log("errMsg acceptatie_start_testscript");
          // console.log(errMsg);
          // console.log(textStatus);
          // console.log(errorThrown);
        },
        complete: function(jqXHR,status){

          $('#spinner').hide();
          //setRightPanel();

        }
     }); // end Ajax
  };

  $(document).on('change', '.acc_testcases_physical-status', function(e){
    e.stopPropagation();
    e.preventDefault();
    change_acceptatie_testcase_physical(this);
  });


  $(document).on('change', '.acc_testcases_physical-info', function(e){
    e.stopPropagation();
    e.preventDefault();
    change_acceptatie_testcase_physical(this);
  });


  function change_acceptatie_testcase_physical(this_){
    var status_id = $(this_).closest('div.acceptatie_testscript-data').find('.acc_testcases_physical-status').val();
    var info = $(this_).closest('div.acceptatie_testscript-data').find('.acc_testcases_physical-info').val();
    var acceptatie_testscript_id = $(this_).closest('div.acceptatie_testscript-data').attr('acceptatie_testscript_id');
    var acceptatie_test_id = $(this_).closest('div.acceptatie_testscript-data').attr('acceptatie_test_id');
    var testcases_physical_id = $(this_).closest('div.acceptatie_testscript-data').attr('testcases_physical_id');

    var data = {
      "notities": info,
      "status_id":status_id, 
      "acceptatie_testscript_id":acceptatie_testscript_id, 
      "acceptatie_test_id":acceptatie_test_id, 
      "testcases_physical_id":testcases_physical_id };

    $.ajax({
        type: "POST",
        url: "/create_acceptatie_testcase_physical",
        data: JSON.stringify(data),
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        beforeSend: function(request){
          request.setRequestHeader( "X-CSRF-Token", document.getElementsByName("csrf-token")[0].content);
          $('#spinner').show();
        },
        success: function(data, status){
          //console.log(data);          
        }, 
        error : function(errMsg, textStatus, errorThrown) {
            
        },
        complete: function(jqXHR,status){
          $('#spinner').hide();
        }
     }); // end Ajax
  };

// END ACCEPTATIES


// KETENTESTS
$(document).on('click', '.tr_start_ketentest', function(){
    var id = $(this).data('id');
    console.log( id );
    $(document).find('.kt_box_selected').removeClass('kt_box_selected');
    $(this).addClass('kt_box_selected');
    //, :locals => {:interactie => @partial, :title => @title }

    ketentest_start_testscript(id);
  });


$(document).on('change', '.role_radios', function() {
   var roleId = $('input[name="role_radios"]:checked').val();
   var userId = $('#userid').data('userid');
   
      $.ajax({
         type: "POST",
         url: "/user/set_role/" + userId,
         data: {"role-id": roleId},
         dataType: "json",
         // contentType: "application/json; charset=utf-8",
         beforeSend: function(request){
            request.setRequestHeader( "X-CSRF-Token", document.getElementsByName("csrf-token")[0].content);
         },
         success: function(data, status){   
         },
         error: function(errMsg) {
         },
         complete: function(jqXHR,status){
         }
      });
});


function ketentest_start_testscript(id){
    $.ajax({
        type: "GET",
        url: "/ketentest_start_testscript/" + id,
        // data: JSON.stringify(data),
        // contentType: "application/json; charset=utf-8",
        dataType: "json",
        beforeSend: function(request){
          //request.setRequestHeader( "id", id ); 
          //console.log("before ketentest_start_testscript");
          $('#spinner').show();
          $('#ketentest_start_index').css('height','12vh');
        },
        success: function(data, status){

          //console.log("success ketentest_start_testscript");
          //console.log(data);
          $('#start_testscript').html(data.testscript);
          $('#ketentest_testscript_chat').html(data.chatroom);


        }, 
        error : function(errMsg, textStatus, errorThrown) {
          console.log("errMsg ketentest_start_testscript");
          console.log(errMsg);
        },
        complete: function(jqXHR,status){

          $('#spinner').hide();

        }
     }); // end Ajax
  };

  $(document).on('change', '.kt_testcases_physical-status', function(e){
    e.stopPropagation();
    e.preventDefault();
    change_ketentest_testcase_physical(this);
  });


  $(document).on('change', '.kt_testcases_physical-info', function(e){
    e.stopPropagation();
    e.preventDefault();
    change_ketentest_testcase_physical(this);
  });

   // $(document).on('change', '.acc_tscript_physical-info', function(e){
   //     e.stopPropagation();
   //     e.preventDefault();
   //     change_ketentest_testcase_physical(this);
   //   });


  function change_ketentest_testcase_physical(this_){
    var status_id = $(this_).closest('div.ketentest_testscript-data').find('.testcases_physical-status').val();
    var info = $(this_).closest('div.ketentest_testscript-data').find('.kt_testcases_physical-info').val();
    var ketentest_testscript_id = $(this_).closest('div.ketentest_testscript-data').attr('ketentest_testscript_id');
    var testcases_physical_id = $(this_).closest('div.ketentest_testscript-data').attr('testcases_physical_id');

    var data = {
      "info": info,
      "status_id":status_id, 
      "ketentest_testscript_id":ketentest_testscript_id, 
      "testcases_physical_id":testcases_physical_id };

    $.ajax({
        type: "POST",
        url: "/create_ketentest_testcase_physical",
        data: JSON.stringify(data),
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        beforeSend: function(request){
          request.setRequestHeader( "X-CSRF-Token", document.getElementsByName("csrf-token")[0].content);
          $('#spinner').show();
        },
        success: function(data, status){
          //console.log(data);          
        }, 
        error : function(errMsg, textStatus, errorThrown) {
            
        },
        complete: function(jqXHR,status){
          $('#spinner').hide();
        }
     }); // end Ajax
  };

  // END KETENTESTS


  // ACCEPTATIES

$(document).on('click', '#back_table_stats_acc_test', function() {
      $('#acc_stats_leveranciers').show();
      $('#js_acc_stats_leverancier').html('');
   });

   $(document).on('click', '.acc_stats_leveranciers_row', function() {
      // user id
      console.log( "user id: " + $(this).data('id') );
      console.log( "set  id: " + $(this).data('setid') );
      var setid = $(this).data('setid');
      var userid = $(this).data('id');
      // acceptatietest/stats/:id
      $.ajax({
           type: "GET",
           url: "/acceptatietest/stats/" + userid + "/" + setid,
           // data:  data ,
           dataType: "json",
           beforeSend: function(request){
              request.setRequestHeader( "X-CSRF-Token", document.getElementsByName("csrf-token")[0].content);
              $('#acc_stats_leveranciers').hide();
           },
           success: function(data, status){
              //console.log("success");
              //console.log(data);
              $('#js_acc_stats_leverancier').html(data.html)
           },
           error: function(errMsg) {
              //console.log(errMsg);
           },
           complete: function(jqXHR,status){
              //console.log("complete:");
           }
       });
   });

   $(document).on('click', '#js_acc_test_stats_per_id', function() {
      $('#acc_stats_acceptatie_tests').show();
      $('#js_acc_test_stats_per_id').html('');
   });

   $(document).on('click', '.tr_acc_test_stats_leveranciers', function() {
      console.log( $(this).data('id') );
      // acceptatietest/stats/:id
      $.ajax({
           type: "GET",
           url: "/acceptatietest/stats_per_lev/" + $(this).data('id'),
           // data:  data ,
           dataType: "json",
           beforeSend: function(request){
              request.setRequestHeader( "X-CSRF-Token", document.getElementsByName("csrf-token")[0].content);
              $('#acc_stats_acceptatie_tests').hide();
           },
           success: function(data, status){
              //console.log("success");
              //console.log(data);
              $('#js_acc_test_stats_per_id').html(data.html)
           },
           error: function(errMsg) {
              //console.log(errMsg);
           },
           complete: function(jqXHR,status){
              //console.log("complete:");
           }
       });
   });
   

   $(document).on('click', '.acc_stats_accepatiesets_row', function() {
      // set id
      console.log( $(this).data('id') );

      $.ajax({
           type: "GET",
           url: "/acceptatietest/stats_get_users/" + $(this).data('id'),
           // data:  data ,
           dataType: "json",
           beforeSend: function(request){
              request.setRequestHeader( "X-CSRF-Token", document.getElementsByName("csrf-token")[0].content);
              $('#acc_stats_acceptatiesets').hide();
           },
           success: function(data, status){
              //console.log("success");
              //console.log(data);
              $('#js_acc_stats_levs_per_set').html(data.html)
           },
           error: function(errMsg) {
              //console.log(errMsg);
           },
           complete: function(jqXHR,status){
              //console.log("complete:");
           }
       });
   });


   $(document).on('click', '.tr_acceptatie_set', function(e) {

      e.stopPropagation();
      e.preventDefault();

      console.log( $(this) );

      $(document).find('.tr_acceptatie_set.clicked').removeClass('clicked');

      $(this).addClass('clicked');

      $.ajax({
          type: "GET",
          url: "/acceptatietest/get-testscript/" + $(this)[0].dataset.setid,
          dataType: "json",
          beforeSend: function(request){            

          },
          success: function(data, status){
            console.log(data);
            $('#js_acceptatie_testscripts_title').html(data.title);
            $('#js_acceptatie_testscripts').html(data.acceptatie_testscripts);
          },
          error: function(errMsg) {

          },
          complete: function(jqXHR,status){

          }
       });

   });

  // END ACCEPTATIES

  $(document).on('click', "#checkitem_usepas", function(){
    var item = $(this);
    var usepas = false;

    if (item.hasClass("itemactive")){
  
      //$('#signsamlwithcard').attr('fill', "lightgreen");
      item.removeClass("itemactive");
      //$(".customcheck").css("background-color", "#d8d8d8");
      //$("input[name='switch']").prop("checked", false);
      usepas = false;

    } else {    
   
      //$('#signsamlwithcard').attr('fill', "grey");
      item.addClass("itemactive");
      //$(".customcheck").css("background-color", "#befbbe")
      //$("input[name='switch']").prop("checked", true);
      usepas = true;

    }

    $.ajax({
      type: "POST",
      url: "/usepas",
      data: JSON.stringify({"usepas":usepas}),
      processData: false,
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      beforeSend: function(request){
        request.setRequestHeader("X-CSRF-Token", document.getElementsByName("csrf-token")[0].content);
      },
      success: function(data, status){
        console.log("succes");
        location.reload();
      },
      error: function(errMsg) {
      
      }
    });
  });

  //  $(document).on("keyup change", "#ura", function(e) {
  
  //   if ( $('#ura')[0].value.length > 0 ) {
  //     document.getElementById('naam').disabled = true;
  //     //document.getElementById('woonplaats').disabled = true;
  //     $('#zabformbtn').attr('disabled', false);   
  //   } else {
  //     document.getElementById('naam').disabled = false;
  //   //  document.getElementById('woonplaats').disabled = false;   
  //     $('#zabformbtn').attr('disabled', true); 
  //   }
  // });

  // $(document).on("keyup change", "#naam", function(e) {
  
  //   if ( $('#ura')[0].value.length == 0  ) {

  //     if ( $('#naam')[0].value.length > 0 ) {
  //       document.getElementById('ura').disabled = true;
  //       $('#naam').css("border", "1px solid #ced4da");
  //     } else {
  //       document.getElementById('ura').disabled = false;
  //       $('#naam').css("border", "1px solid #ced4da");
  //     }
        
  //   //  if ( $('#woonplaats')[0].value.length > 0 ) {     
  //   //    document.getElementById('ura').disabled = true;
  //   //    $('#woonplaats').css("border", "1px solid #ced4da");
  //   //  }  else {
  //   //    $('#woonplaats').css("border", "1px solid red");
  //   //  }
    
  //     if ( $('#naam')[0].value.length > 0  ) {      
  //       $('#zabformbtn').attr('disabled', false); 
  //     } else {
  //       $('#zabformbtn').attr('disabled', true); 
  //     }
      
  //   } // end if ( $('#ura')
    
  // });

   $(document).on("keyup change", ".zoek_zab_class", function(e) {
  
      
      var allElementsButThis = $('.zoek_zab_class').not(this);

      //console.log( "this length: " + $(this)[0].value.length );

      allElementsButThis.attr('disabled', true);

      if ( $(this)[0].value.length == 0 ) {
         allElementsButThis.attr('disabled', false);
         $('#zabformbtn').attr('disabled', true); 
      } else {
         $('#zabformbtn').attr('disabled', false); 
      }
    
  });

  $(document).on("click", '#zabformbtn', function(e){
    
      e.preventDefault();
      //e.stopPropagation();

      var data = {
         "ura": document.getElementById('ura').value,
         "naam": document.getElementById('naam').value,
         "medmij": document.getElementById('medmij').value
      }

    
      $.ajax({
         type: "POST",
         url: "/zab_request",
         data: JSON.stringify(data),
         contentType: "application/json; charset=utf-8",
         dataType: "json",
         beforeSend: function(request){
            request.setRequestHeader("X-CSRF-Token", document.getElementsByName("csrf-token")[0].content);
            $('#displayName').text("").removeClass('alert2')
            $('#loaderZab').show();  

            $('#displayId').text(''); 
            $('#displayUra').text('');  
            $('#displayMedMij').text('');           
            $('#displayAddress').text('');
            $('#displayTimeStamp').text('');    
            $('#showJson').hide();      

            var ddlAppIds = document.getElementById("appids");
            $(ddlAppIds).find('option').remove(); 

         },
         success: function(data, status){
          
            //console.log(data);
            //console.log("status: " + status);
                      
            if (status == "success") {

          
              //var parsed1 = JSON.parse(data.response);
              var parsed1 = data.response;
              var parsed;
            
              if (Array.isArray(parsed1)) {
                //console.log("Array");
                    parsed = parsed1[0];
              } else {
                //console.log("geen Array");          
                    parsed = parsed1;         
              }
        
              //console.log(parsed);

              if (parsed.applicationIds) {
              
              PopulateDropDownList( parsed.applicationIds );
              document.getElementById("other").value = "";
           
               } else {
               
              // <option value="" disabled selected>Zoek eerst in het ZAB hierboven, kies daarna een applicatie ID</option>
               
                var ddlAppIds = document.getElementById("appids");
                $(ddlAppIds).find('option').remove();
                var option = document.createElement("OPTION");
                option.innerHTML = "Geen app-id's beschikbaar";
                option.value = '';
                option.disabled = 'true';
                option.selected = 'true';
                ddlAppIds.options.add(option);
              }

            if (parsed._id) {
                $('#displayId').text(parsed._id); 
            }
              
            if (parsed.displayName) {         
                $('#displayName').text(parsed.displayName);
            };

            if (parsed.ura) {
                $('#displayUra').text(parsed.ura);  
            };
                
            if (parsed.identifications) { 
                $('#displayMedMij').text(parsed.identifications["0"].value);
            }

            if (parsed.addresses) {
                $('#displayAddress').text( parsed.addresses["0"].address );
                $('#displayTimeStamp').text(parsed.addresses["0"].timestamp);             
            }

            $('#showJson').show();
            //$('#ta_request').text(data.varRequest);
            $('#MyModalBody').html(JSON.stringify(data.response));
            //autoExpand($('#ta_response')[0]);   
            //autoExpand($('#ta_request')[0]);    
            
          } else {
          
            $('#displayName').text(data.message);
            $('#displayId').text(''); 
            $('#displayUra').text('');  
            $('#displayMedMij').text('');           
            $('#displayAddress').text('');
            $('#displayTimeStamp').text('');    
            $('#showJson').hide();    
            
          }
          
          $('#loaderZab').hide();
        },
        error: function(errMsg) {
          console.log(errMsg)

          if (errMsg.responseJSON)  {

            if (errMsg.responseJSON.response) {

               $('#displayName').text(errMsg.responseJSON.response.error).addClass('alert2').show();
            } else {
               $('#displayName').text("Some error occurred");   
            }

         }

            $('#loaderZab').hide();
            $('#displayId').text(''); 
            $('#displayUra').text('');  
            $('#displayMedMij').text('');           
            $('#displayAddress').text('');
            $('#displayTimeStamp').text('');    
            $('#showJson').hide();   
            // console.log(errMsg.responseJSON.response.error);
            $('#MyModalBody').html(JSON.stringify(data.response2));

        },
        complete: function(jqXHR,status){
          //console.log("complete: "  + url );
          $('#loader').hide();
      }
     });

      return false; 
      
    });  //zabformbtn
    // $(document).on("click", '#zabformbtn', function(e){
    
    //   e.preventDefault();
    //   //e.stopPropagation();
      
    //   var url1 = "/zab_request_ura";
    //   var url2 = "/zab_request_search";
    //   var url = "/dummy"
    //   var data = {};
      
    //   if (!document.getElementById('ura').disabled) {
    //     //naam + wnplts
    //     url = url1;
    //     data = {"ura": document.getElementById('ura').value};
    //   } else {
    //     // alleen ura
    //     data = {"naam": document.getElementById('naam').value };
    //     url = url2;
    //   }

    
    //   $.ajax({
    //     type: "POST",
    //     url: url,
    //     data: JSON.stringify(data),
    //     contentType: "application/json; charset=utf-8",
    //     dataType: "json",
    //     beforeSend: function(request){
    //      request.setRequestHeader("X-CSRF-Token", document.getElementsByName("csrf-token")[0].content);

    //      $('#loaderZab').show();
            
    //        if (!document.getElementById('ura').disabled) {
    //         request.setRequestHeader( "ura", document.getElementById('ura').value );
    //        } else {
    //         request.setRequestHeader( "naam", document.getElementById('naam').value );         
    //        }         
         
    //    },
    //    statusCode: {
    //           404: function() {
    //              // console.log("-1-1-1-1 WE GOT 404!");
    //           },
    //           200: function() {
    //              // console.log("-1-1-1-1 WE GOT 200!");
    //           }
    //       },
    //     success: function(data, status){
          
    //         //console.log(data);
    //         //console.log("status: " + status);
                      
    //         if (status == "success") {

          
    //           //var parsed1 = JSON.parse(data.response);
    //           var parsed1 = data.response;
    //           var parsed;
            
    //           if (Array.isArray(parsed1)) {
    //             //console.log("Array");
    //                 parsed = parsed1[0];
    //           } else {
    //             //console.log("geen Array");          
    //                 parsed = parsed1;         
    //           }
        
    //           //console.log(parsed);

    //           if (parsed.applicationIds) {
              
    //           PopulateDropDownList( parsed.applicationIds );
    //           document.getElementById("other").value = "";
           
    //            } else {
               
    //           // <option value="" disabled selected>Zoek eerst in het ZAB hierboven, kies daarna een applicatie ID</option>
               
    //             var ddlAppIds = document.getElementById("appids");
    //             $(ddlAppIds).find('option').remove();
    //             var option = document.createElement("OPTION");
    //             option.innerHTML = "Geen app-id's beschikbaar";
    //             option.value = '';
    //             option.disabled = 'true';
    //             option.selected = 'true';
    //             ddlAppIds.options.add(option);
    //           }

    //         if (parsed._id) {
    //             $('#displayId').text(parsed._id); 
    //         }
              
    //         if (parsed.displayName) {         
    //             $('#displayName').text(parsed.displayName);
    //         };

    //         if (parsed.ura) {
    //             $('#displayUra').text(parsed.ura);  
    //         };
                
    //         if (parsed.identifications) { 
    //             $('#displayMedMij').text(parsed.identifications["0"].value);
    //         }

    //         if (parsed.addresses) {
    //             $('#displayAddress').text( parsed.addresses["0"].address );
    //             $('#displayTimeStamp').text(parsed.addresses["0"].timestamp);             
    //         }

    //         $('#showJson').show();
    //         //$('#ta_request').text(data.varRequest);
    //         $('#MyModalBody').html(JSON.stringify(data.response));
    //         //autoExpand($('#ta_response')[0]);   
    //         //autoExpand($('#ta_request')[0]);    
            
    //       } else {
          
    //         $('#displayName').text(data.message);
    //         $('#displayId').text(''); 
    //         $('#displayUra').text('');  
    //         $('#displayMedMij').text('');           
    //         $('#displayAddress').text('');
    //         $('#displayTimeStamp').text('');    
    //         $('#showJson').hide();    
            
    //       }
          
    //       $('#loaderZab').hide();
    //     },
    //     error: function(errMsg) {
    //       console.log(errMsg)

    //         $('#loaderZab').hide();
    //         $('#displayName').text(errMsg.responseJSON.error);
    //         $('#displayId').text(''); 
    //         $('#displayUra').text('');  
    //         $('#displayMedMij').text('');           
    //         $('#displayAddress').text('');
    //         $('#displayTimeStamp').text('');    
    //         $('#showJson').hide();   
    //         // console.log(errMsg.responseJSON.response.error);

    //     },
    //     complete: function(jqXHR,status){
    //       //console.log("complete: "  + url );
    //       $('#loader').hide();
    //   }
    //  });

    //   return false; 
      
    // });  //zabformbtn

  var varSbvzOntvangen = false;
  var varHandmatigChecked = false;

$(document).on('change', '#handmatig_adres', function() {
      var varHandmatigChecked = $(this).is(":checked");

      if (varHandmatigChecked) {

        $('.token_not_selected').css("color","black");
        $('#custom_Bsn').val( $("#inschrijftoken_id option:selected").text().split(",")[0] );
        $('#custom_address').show();
        $('#formButton').prop('disabled', false).children().text('Verstuur met handmatig adres');
      
      } else {

        if (varSbvzOntvangen) {
          $('.token_not_selected').css("color","black");        
          $('#formButton').prop('disabled', false).children().text('Verstuur met opgehaald BSN');
        
        } else {

          var varText = $("#inschrijftoken_id option:selected").text().split(",")[0];
          $('.token_not_selected').css("color","red");        
          $('#formButton').prop('disabled', true).children().text( varText );
        
        }
        
        $('#custom_address').hide();

      }
  });
  
  $(document).on('click', '#opvragenSBVZ', function(e) {

    
    e.preventDefault();  

    var sbvzFormBsn = document.getElementById("sbvzFormBsn").value;
    var data = {"bsn":sbvzFormBsn.trim()};

    $.ajax({
        type: "GET",
        url: "/bsnopvragen",
//      data: JSON.stringify(data),
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        beforeSend: function(xhr){
         xhr.setRequestHeader("bsn",sbvzFormBsn.trim());
         $('#loaderSBVZ').show();
         $('.btn_download').hide();
     },
        success: function(data, status){

          console.log(data);

          varSbvzOntvangen = true;
          var response = data.response;
          
          var divCode = document.getElementById("codeSVBZ");
          var divDisplay = document.getElementById("displaySVBZ");
          
          $(divCode).text("ResponseCode = " + response.code);

          if (response.mes_displayName != null) {
            $(divDisplay).text(response.mes_displayName).css("font-style","italic");
          }

          if (response.code != "OK") {
              $(divCode).addClass('red').removeClass('limegreen');

              if ($('#handmatig_adres')){
                $('#handmatig_adres').prop("checked", true).trigger("change"); //.prop("checked", true);
              } 

              $('#queryResponseCodeSVBZ').fadeIn();
              $('#bsnResponseSVBZ').fadeOut();
              //$('#formButton').prop("disabled", true).children().text("Eerst BSN ophalen");
               

            } else {
              $(divCode).addClass('limegreen').removeClass('red');

              if ($('#handmatig_adres')){
                $('#handmatig_adres').prop("checked", false).trigger("change");
              }

              $('#queryResponseCodeSVBZ').fadeOut();
              $('#bsnResponseSVBZ').fadeIn();
              $('#formButton').prop("disabled", false).children().text("Verstuur");

          }
              
          var json = response;
              
  //      console.log(json);          

          var varTrTh = "";
          var varTrTd = "";
            
            //var varCount = Object.keys(json).length;  
            
          Object.keys(json.person).forEach(function(key,index) {
    //        console.log("Key = " + key + ", Index = " + index );
            var varTrThNew = "<th>" + key + "</th>  \n" ;
            varTrTh = varTrTh + varTrThNew;
            });
            
          Object.values(json.person).forEach(function(value,index) {
    //        console.log("value = " + value + ", Index = " + index );
             var varTrTdNew = "<td>" + value + "</td>  \n" ;
             varTrTd = varTrTd + varTrTdNew;
            });

          Object.entries(json.person).forEach(function([k,v]) {
            //console.log(k,v);
            window.sessionStorage.setItem(k,v);
          });

          // Object.entries(json.person).forEach(([key, value]) => {
          //   console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"
          // });

          var varHtml = '<div class="table-responsive"> \n' +
            '<table id="table"> \n' +
            //'<table id="tableBsnResponses"> \n' +
              '<thead id="av"> \n' +
               "<tr> \n" + varTrTh + "</tr> \n" +
               "</thead> \n" +
              "<tbody> \n" +
                "<tr> \n" + varTrTd + "</tr> \n" +
              "</tbody> \n" +
            "</table> \n" + 
            '</div>';
             // End varHtml

    //        console.log(varHtml);
          var element = document.getElementById("bsnResponseSVBZ");
          
          $(element).html(varHtml);     
          //$('#ta_response_bsn').text(data.response2);

          //$(codeMirror_XML[codeMirror_XML_ids['ta_response_bsn'][0]].getWrapperElement()).show();
          //$(codeMirror_XML[codeMirror_XML_ids['ta_response_bsn'][0]].getWrapperElement()).show(); 
          codeMirror_XML[codeMirror_XML_ids['ta_response_bsn']].setValue(new XmlBeautify().beautify(data.response2,{indent: "  ", useSelfClosingElement: true}));
          codeMirror_XML[codeMirror_XML_ids['ta_response_bsn']].setSize("100%", "450px");
          codeMirror_XML[codeMirror_XML_ids['ta_response_bsn']].refresh();

        

          //$('#ta_response').text(data.response2);
          //$('#ta_request').text(data.request.args.payload);
          // varHeaders = "";
          // varHeaders += "<li>" + "Method" + ": " + data.request.method + "</li>";
          // varHeaders += "<li>" + "Url" + ": " + data.request.url + "</li>";
          // $.each(data.request.headers, function(k,v) {        
          //     varHeaders += "<li>" + k + ": " + v + "</li>";
          // });
          //$('#request_headers').html(varHeaders);
            
          window.sessionStorage.setItem("bsn", sbvzFormBsn);
          $('.btn_download').show();

        },
        error: function(errMsg) {
            //console.log(errMsg);
            varSbvzOntvangen = false;

            var element = document.getElementById("bsnResponseSVBZ");
            $(element).html('');    
      
            $('#queryResponseCodeSVBZ').text(errMsg.status + ' ' + errMsg.statusText).fadeIn();
            $('#loaderSBVZ').hide();
            $('#ta_response').text(errMsg.responseJSON.response2);
            $('#ta_request').text(errMsg.responseJSON.request.args.payload);
            $('#request_uri').text(errMsg.responseJSON.request.args.url);

            Object.entries(errMsg.responseJSON.request.args.headers).forEach(function([k,v]) {
              $('#request_headers').prepend( k + ', ' + v + '\n');
            });
            codeMirror_XML[codeMirror_XML_ids['ta_response_bsn']].setValue(new XmlBeautify().beautify(errMsg.responseJSON.response2,{indent: "  ", useSelfClosingElement: true}));
          codeMirror_XML[codeMirror_XML_ids['ta_response_bsn']].setSize("100%", "450px");
          
          $(codeMirror_XML[codeMirror_XML_ids['ta_response_bsn']].getWrapperElement()).toggle();
          codeMirror_XML[codeMirror_XML_ids['ta_response_bsn']].refresh();

        },
        complete: function(jqXHR,status){
            $('#loaderSBVZ').hide();
            autoExpand($('#ta_response')[0]);   
            autoExpand($('#ta_request')[0]); 

      }
     });
  
    }); // opvragen SVBZ Click end

$(document).on('click', '#ta_response_bsn_btn', function() {
  //$('#ta_response_bsn').toggle();
    $(codeMirror_XML[codeMirror_XML_ids['ta_response_bsn']].getWrapperElement()).toggle();
    codeMirror_XML[codeMirror_XML_ids['ta_response_bsn']].refresh();
});

$(document).on('click', '#ta_response_vwi_btn', function() {
  //$('#ta_response_bsn').toggle();
    $(codeMirror_XML[codeMirror_XML_ids['ta_response_vwi']].getWrapperElement()).toggle();
    codeMirror_XML[codeMirror_XML_ids['ta_response_vwi']].refresh();
});

 window.PopulateDropDownList = function(appids) {
            
   // <option value="DOHAMX">ACTMEDAFS - Actuele medicatie</option>
   
    var ddlAppIds = document.getElementById("appids");
    $(ddlAppIds).find('option').remove(); 
   
    //Add the Options to the DropDownList.
    for (var i = 0; i < appids.length; i++) {
        var option = document.createElement("OPTION");

        //Set Customer Name in Text part.
        option.innerHTML = appids[i];

        //Set CustomerId in Value part.
        option.value = appids[i];

        //Add the Option element to DropDownList.
        ddlAppIds.options.add(option);
    }

    $(ddlAppIds).css("color", "red");

}


 window.ToggleNextTd = function(node) {
    var displayMode = '';
    if (node.innerText == "-") // if contents is to be collapsed
    {
        node.innerText = "+";
        displayMode = 'none';
        // Make a possible collapse button in the second row invisible
        if (node.parentNode.nextElementSibling) {
            node.parentNode.nextElementSibling.style.display = 'none';
        }
    } else {
        node.innerText = "-";
        displayMode = '';
        // Make a possible collapse button in the second row visible
        if (node.parentNode.nextElementSibling) {
            node.parentNode.nextElementSibling.style.display = '';
        }
    }
}

 window.ToggleNextTr = function(node) {
    var displayMode = '';
    if (node.innerText == "-") // if contents is to be collapsed
    {
        node.innerText = "+";
        displayMode = 'none';
        // Make a possible collapse button in the second row invisible
        if (node.parentNode.parentNode.nextElementSibling) {
            node.parentNode.parentNode.nextElementSibling.style.display = 'none';
        }
    } else {
        node.innerText = "-";
        displayMode = '';
        // Make a possible collapse button in the second row visible
        if (node.parentNode.parentNode.nextElementSibling) {
            node.parentNode.parentNode.nextElementSibling.style.display = '';
        }
    }
}

 window.collapseAll = function() {
    // collapse all collapsible TD's
    var collapsibleElements = document.querySelectorAll('[class^=collapsibleTd]');
    for (var i in collapsibleElements) {
        if (collapsibleElements[i].firstElementChild)
            collapsibleElements[i].firstElementChild.innerText = "+";
        if (collapsibleElements[i].nextElementSibling) {
            collapsibleElements[i].nextElementSibling.style.display = 'none';
        }
    }
    
    // collapse all collapsible TR's
    var collapsibleTrElements = document.querySelectorAll('[class^=collapsibleTr]');
    for (var i in collapsibleTrElements) {
        if (collapsibleTrElements[i].firstElementChild)
            collapsibleTrElements[i].firstElementChild.firstElementChild.innerText = "+";
        if (collapsibleTrElements[i].nextElementSibling) {
            collapsibleTrElements[i].nextElementSibling.style.display = 'none';
        }
    }
    
    // collapse all collapsible MBH's  (Medicatie)
    var collapsibleMBHElements = document.querySelectorAll('[class^=collapsibleMBH]');
    for (var i in collapsibleMBHElements) {
        collapsibleElements[i].innerText = "-";
        ToggleMBH(collapsibleMBHElements[i]);
    }
    /*  CollapseMBH also collapses ID's  */
}
 window.expandAll = function() {
    // expand all collapsible TD's
    var collapsibleElements = document.querySelectorAll('[class^=collapsibleTd]');
    for (var i in collapsibleElements) {
        if (collapsibleElements[i].firstElementChild)
            collapsibleElements[i].firstElementChild.innerText = "-";
        if (collapsibleElements[i].nextElementSibling) {
            collapsibleElements[i].nextElementSibling.style.display = '';
        }
    }
    
    // expand all collapsible TR's
    var collapsibleTrElements = document.querySelectorAll('[class^=collapsibleTr]');
    for (var i in collapsibleTrElements) {
        if (collapsibleTrElements[i].firstElementChild)
            collapsibleTrElements[i].firstElementChild.firstElementChild.innerText = "-";
        if (collapsibleTrElements[i].nextElementSibling) {
            collapsibleTrElements[i].nextElementSibling.style.display = '';
        }
    }

    // expand all collapsible MBH's  (Medicatie)
    var collapsibleMBHElements = document.querySelectorAll('[class^=collapsibleMBH]');
    for (var i in collapsibleMBHElements) {
        collapsibleMBHElements[i].innerText = "+";
        ToggleMBH(collapsibleMBHElements[i]);
    }
    
    // expand all collapsible ID's  (Medicatie)
    var collapsibleIdElements = document.querySelectorAll('[class^=collapsibleID]');
    for (var i in collapsibleIdElements) {
        collapsibleIdElements[i].innerText = "+";
        ToggleID(collapsibleIdElements[i]);
    }
}


/*  Functie uit message2html voor uitklappen van sections. */
 window.toggle = function(toggled, toggler) {
    if (document.getElementById) {
        var currentStyle = document.getElementById(toggled).style;
        var togglerStyle = document.getElementById(toggler).style;
        if (currentStyle.display == "block") {
            currentStyle.display = "none";
            togglerStyle.backgroundImage = "url(trClosed.gif)";
        } else {
            currentStyle.display = "block";
            togglerStyle.backgroundImage = "url(triangleOpen.gif)";
        }
        return false;
    } else {
        return true;
    }
}



/*functies voor MP9 viewer*/
  /* This function toggles the visibility of all table rows containing information of the same MBH,
  except for the topmost row of that MBH, which is always visible and from which the toggle is triggered. 
  It should be called from an element with an onclick method, like a button or hyperlink.              
  */
   window.ToggleMBH = function(node)
  {
    var displayMode;
    if (node.innerText == "-")  // if MBH is to be collapsed
    {
      node.innerText = "+";
      displayMode = 'none';
      // Make a possible collapse button in the second row invisible
      if (node.parentNode && node.parentNode.nextElementSibling.firstElementChild)
      {
        node.parentNode.nextElementSibling.firstElementChild.style.display = 'none';
        node.parentNode.nextElementSibling.firstElementChild.innerText = '+';
      }
    }
    else
    {
      node.innerText = "-";
      displayMode = '';
      // Make a possible collapse button in the second row visible
      if (node.parentNode && node.parentNode.nextElementSibling.firstElementChild)
      {
        node.parentNode.nextElementSibling.firstElementChild.style.display = '';
      }
    }
    
    if (node.parentNode == null)
      return;
    
    // Go through all rows and toggle its visibility if it is part of the same MBH 
    var sibling = node.parentNode.parentNode;  // Go to the TR node
    while (sibling = sibling.nextElementSibling)
    {
      if (sibling.className == node.parentNode.parentNode.className)
      if ((sibling.id != node.parentNode.parentNode.id) || (node.innerText == "+"))
      {
        // Set hide all rows when collapsing
        if ((displayMode != '') || (sibling.id != sibling.previousElementSibling.id))
        sibling.style.display = displayMode;
        if (displayMode == 'none')
        {
          // set all second column collapse button texts to '+'
          if (sibling.firstElementChild.nextElementSibling.firstElementChild)
          sibling.firstElementChild.nextElementSibling.firstElementChild.innerText = '+';             
        }
        else
        {
          if (sibling.firstElementChild.nextElementSibling.firstElementChild)
          sibling.firstElementChild.nextElementSibling.firstElementChild.style.display = '';             
        }
      }
    }
  }
  
   window.ToggleID = function(node)
  {
    var displayMode;
    if (node.innerText == "-")  // if ID is to be collapsed
    {
      node.innerText = "+";
      displayMode = 'none';
    }
    else
    {
      node.innerText = "-";
      displayMode = '';
    }
    
    // Go through all rows and toggle its visibility if it is part of the same MBH 
    var sibling = node.parentNode.parentNode;  // Go to the TR node
    while (sibling = sibling.nextElementSibling)
    {
      if (sibling.id == node.parentNode.parentNode.id)
        if (sibling.className == node.parentNode.parentNode.className)
          sibling.style.display = displayMode;
    }
  }         
  
   window.toggleDebug = function()
  {
    var debugElements = document.querySelectorAll('[id^=debugInfo]');
    for(var i in debugElements)
    {
      debugElements[i].classList.toggle('hideDebugInfo');
    }
  }

  $(document).on('click', '.stopPropagation', function(e){
    e.stopPropagation();
  });


   window.test = function(name) {
    name = name || 'unk';
  }

   window.isEmpty = function(obj) {
      for(var key in obj) {
          if(obj.hasOwnProperty(key))
              return false;
      }
      return true;
  }

   window.download = function(filename, text) {

    //event.stopPropagation();
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  $(window).on("blur focus", function(e) {
    var prevType = $(this).data("prevType");

    if (prevType != e.type) {   //  reduce double fire issues
        switch (e.type) {
            case "blur":
                console.log("User left");
                userActive = false;
                // userActiveTimeMs = gon.user_timeout * 1000;
                userActiveTimeMs = 30 * 1000;
                break;
            case "focus":
                console.log("User back");
                userActive = true;
                userActiveTimeMs = msActiveTime;
                break;
        }
    }

    $(this).data("prevType", e.type);
})

 window.timerIncrement = function() {
    idleTime = idleTime + 1;
    if (idleTime > 2) { // 20 minutes
        userActiveTimeMs = 30000;
    }
  }

if (document.getElementById("inschrijftoken_id") != null) {
  window.sessionStorage.setItem("bsn", $("#inschrijftoken_id option:selected").text().split(",")[0]);

}

$(document).on('change', '#inschrijftoken_id', function (){

  if ($('#inschrijftoken_id')[0].selectedIndex === 0){
    // GEEN BSN MEER GESELECTEERD (EERSTE OPTION)

    $('.token_not_selected').css("color","red");
    $('#formButton').prop('disabled', true).children().text('Kies eerst een BSN - inschrijftoken').css("color", "red");

    // if (!varHandmatigChecked) {

    //   console.log("zet bsn shit uit");

    // } else {
    //   console.log("zet bsn shit uit2");      
    // }

  } else {
    $('#formButton').prop('disabled', false).children().text('Verstuur').css("color", "");

    $('.token_not_selected').css("color","black");

    var setBsn = $("#inschrijftoken_id option:selected").text().split(",")[0];


    window.sessionStorage.setItem("bsn", setBsn);

    if (document.getElementById("sbvzFormBsn") != null) {
      document.getElementById("sbvzFormBsn").value = setBsn;
      $('#opvragenSBVZ').attr('disabled', false).click().attr('disabled', true);
    }

    if (document.getElementById("formBsn") != null) {
      // console.log( $("#inschrijftoken_id option:selected").text().split(",")[0] );
      document.getElementById("formBsn").value = setBsn;
    }


    if ( $('#custom_Bsn') ) {
      $('#custom_Bsn').val( $("#inschrijftoken_id option:selected").text().split(",")[0] );
    }

  }

  

});


 window.cleanRequestAndResponse = function() {
    $('#responseText').html("");
    $('#ackDetails').hide();
    $('#showHtml').hide();
    $('#showAck').hide();     
    $('#showAD').hide();   
    $('#showDisplayName').hide();  
    $('#raw_xml_request, #raw_xml_response').hide();

    $('#loader').show();
    $('#ta_request').text('n.v.t.');
    $('#ta_response').text('n.v.t.');
    $('.btn_download').hide();
    $('#btn_view_xml_errors').hide();
    $('#response_headers').hide();
    $('#request_headers').hide();
    $('#request_info').hide();
    $('#response_info').hide();
    $('#btn_view_xml_errors_request').hide();

    codeMirror_XML[codeMirror_XML_ids['ta_request']].setValue("");
    codeMirror_XML[codeMirror_XML_ids['ta_request']].refresh();
    codeMirror_XML[codeMirror_XML_ids['ta_response']].setValue("");
    codeMirror_XML[codeMirror_XML_ids['ta_response']].refresh();
    $(codeMirror_XML[codeMirror_XML_ids['ta_request']].getWrapperElement()).hide();
    $(codeMirror_XML[codeMirror_XML_ids['ta_response']].getWrapperElement()).hide();
}


window.verstuurAjax = function(data, endpoint){

  $.ajax({
      type: "POST",
      url: endpoint,
      data: JSON.stringify(data),
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      beforeSend: function(request){
        ajax_beforeSend(request);
      },
      success: function(data, status){
        ajax_success(data)
      },
      error: function(errMsg) {
        ajax_error(errMsg)  
      },
      complete: function(jqXHR,status){
        ajax_complete(jqXHR,status)
      }
  });

}

window.verstuurAjaxFhir = function(data, endpoint){

  $.ajax({
      type: "POST",
      url: endpoint,
      data: JSON.stringify(data),
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      beforeSend: function(request){
        ajax_beforeSend_Fhir(request);
      },
      success: function(data, status){
        ajax_success_Fhir(data)
      },
      error: function(errMsg) {
        ajax_error_Fhir(errMsg)  
      },
      complete: function(jqXHR,status){
        ajax_complete_Fhir(jqXHR,status)
      }
  });

}

 window.ajax_beforeSend = function(request){
  //console.log("function ajax_beforeSend");  
  $('#formButton').children().text('Versturen en valideren van request en responseberichten, kan even duren!');
  request.setRequestHeader("X-CSRF-Token", document.getElementsByName("csrf-token")[0].content);
  cleanRequestAndResponse();
}  

 window.ajax_beforeSend_Fhir = function(request){
  //console.log("function ajax_beforeSend");  
  $('#formButton').children().text('Versturen, moment aub...');
  request.setRequestHeader("X-CSRF-Token", document.getElementsByName("csrf-token")[0].content);
  cleanRequestAndResponse();
}  //end ajax_beforeSend_Fhir



window.ajax_success = function(data) {

  //console.log("function ajax_success");
  //console.log(data);
  
  var oParser = new DOMParser();
  var oDOM = oParser.parseFromString(data.response, "text/xml");

  if (data.validated) {

    // XML VALIDATION OK
    $('#btn_view_xml_errors').show().text("No XML errors").removeClass('btn-warning').addClass('btn-success');
    var varAck = oDOM.getElementsByTagNameNS("*", "acknowledgement")[0].getAttribute('typeCode');  
         
    //varBerichtId = oDOM.getElementsByTagName("id")[0].getAttribute('extension');  

    $('.btn_download').show();
  
    if (varAck == 'AE') {
          
      //var varCode = oDOM.getElementsByTagNameNS("*", "code")[0].getAttribute('code');
      //var varAD = oDOM.getElementsByTagNameNS("*", "acknowledgementDetail")[0].getAttribute('typeCode');  
      //var varDisplayName = oDOM.getElementsByTagNameNS("*", "code")[0].getAttribute('displayName');
        
      //$('#showAck').text('acknowledgement typeCode: ' + varAck).addClass('alert2').show();
      //$('#showAD').text('acknowledgementDetail typeCode: ' + varAD).addClass('alert2').show();
      //$('#showCode').text('code: ' + varCode).addClass('alert2').show();
      //$('#showDisplayName').text(varDisplayName).addClass('alert2').show();
      //$('#ackDetails').show().removeClass('green').addClass('red');
    
      } else {
    
        //varQueryResponse = oDOM.getElementsByTagName("queryResponseCode")[0].getAttribute('code');

        if( oDOM.getElementsByTagNameNS("*", "queryResponseCode").length > 0 ) {
          var allSelects = oDOM.getElementsByTagNameNS("*","queryResponseCode");
          var lastSelect = allSelects[allSelects.length-1];
          var varQueryResponse = lastSelect.getAttribute('code');
          if (varQueryResponse == 'NF') {                
            $('#showCode').text('queryResponseCode: ' + varQueryResponse).addClass('alert2');
          } else {        
            $('#showCode').text('queryResponseCode: ' + varQueryResponse).removeClass('alert2');
          };
          $('#showCode').text('queryResponseCode: ' + varQueryResponse).show();
         } // end if oDOM.getElementsByTagName

        //$('#showAck').text('acknowledgement typeCode: ' + varAD).removeClass('alert2').show();
        //$('#ackDetails').show().removeClass('red').addClass('green');    
      }
    

  } else {

    // XML VALIDATION ERROR
    
    if( oDOM.getElementsByTagName("queryResponseCode").length > 0 ) {
        $('#btn_view_xmlmulti_errors').show().text("Zie dashboard voor alle bouwstenen").removeClass('btn-success').addClass('btn-info');
    } 

    if (!data.usechannel) {
      $('#btn_view_xml_errors').show().text("Zie validatie").removeClass('btn-success').addClass('btn-warning');
    }

    //$('#showDisplayName').text("XML validation error").addClass('alert2').show();
    //$('#ackDetails').show().removeClass('green').addClass('red');
  }

  $('#queryResponseCode').fadeIn();
  

  //$('#ta_request').text(data.request.args.payload);
  //$('#ta_response').text(data.response);

  //request
  requestBodyRaw = data.request.args.payload;
  requestBodyBeautified = new XmlBeautify().beautify(requestBodyRaw,{indent: "  ", useSelfClosingElement: true});

  $(codeMirror_XML[codeMirror_XML_ids['ta_request']].getWrapperElement()).show();
  codeMirror_XML[codeMirror_XML_ids['ta_request']].setValue(requestBodyRaw);
  codeMirror_XML[codeMirror_XML_ids['ta_request']].refresh();

  //response
  responseBodyRaw = data.response;
  responseBodyBeautified = new XmlBeautify().beautify(responseBodyRaw,{indent: "  ", useSelfClosingElement: true});

  $(codeMirror_XML[codeMirror_XML_ids['ta_response']].getWrapperElement()).show();    
  codeMirror_XML[codeMirror_XML_ids['ta_response']].setValue(responseBodyRaw);
  codeMirror_XML[codeMirror_XML_ids['ta_response']].refresh();

  // varHeadersResponse = "";
  // $.each(data.response_headers, function(k,v) {        
  //     varHeadersResponse += '<li class="header_li">' + k + ": " + v + "</li>";
  // });
  // $('#response_headers').html(varHeadersResponse);

  if (!data.usechannel) {
    $('#response_validation').html(data.validatedhtml);
    $('#request_validation').html(data.validatedhtmlrequest);
  } else {

    if (data.validate) {
      $('#loading-span-response').show();
      $('#loading-span-request').show();
    }

  }

    $('#MyModalHTMLBody').html(data.aortaviewerhtml);
    $('#showHtml').show(); 
    $('.btn_download').show();

  // if (!data.hl7_vzvz && !data.hl7_nictiz) {
  
  //   $('#showDisplayName').text("Geen VZVZ en Nictiz Validatie materiaal").addClass('alert2').show();
  //   $('#btn_view_xml_errors').show().text("Geen VZVZ en Nictiz Validatie materiaal").removeClass('btn-success').removeClass('btn-warning').addClass('btn-secondary');
  
  // } 
  // if (!data.hl7_vzvz && data.hl7_nictiz) {

  //   $('#showDisplayName').text("Geen VZVZ Materiaal").addClass('alert2').show();
  //   $('#btn_view_xml_errors').show().text("Geen VZVZ Materiaal").removeClass('btn-success').removeClass('btn-warning').addClass('btn-secondary');

  // } 
  // if (data.hl7_vzvz && !data.hl7_nictiz) {

  //   $('#showDisplayName').text("Geen Nictiz Materiaal").addClass('alert2').show();
  //   $('#btn_view_xml_errors').show().text("Geen Nictiz Materiaal").removeClass('btn-success').removeClass('btn-warning').addClass('btn-secondary');

  // }
}  // end ajax_success


window.ajax_success_Fhir = function(data) {

  //console.log("function ajax_success");
  console.log(data);

  var oParser = new DOMParser();
  var oDOM = oParser.parseFromString(data.response, "text/xml");

  //request
  requestBodyRaw = data.request.args.payload;
  requestBodyRawSize = (requestBodyRaw != null && requestBodyRaw.length > 0) ? requestBodyRaw.split(/\n/).length * 30 : 30

  $(codeMirror_XML[codeMirror_XML_ids['ta_request']].getWrapperElement()).show();
  codeMirror_XML[codeMirror_XML_ids['ta_request']].setValue(JSON.stringify(requestBodyRaw, null, ' '));
  codeMirror_XML[codeMirror_XML_ids['ta_request']].refresh();
  codeMirror_XML[codeMirror_XML_ids['ta_request']].setSize("100%", requestBodyRawSize);

  //response
  responseBodyRaw = data.response;
  var responseBodyRawFinal = null;
  var responseBodyRawSize = 30;

  if (responseBodyRaw != null && responseBodyRaw.length > 0) {
    if (isJson(responseBodyRaw)){

      responseBodyRawFinal = JSON.stringify(JSON.parse(responseBodyRaw), null, ' ');
      responseBodyRawSize = (responseBodyRaw.length > 20) ? responseBodyRaw.length : responseBodyRaw.length * 3;

    } else {

      responseBodyRawFinal = new XmlBeautify().beautify(responseBodyRaw,{indent: "  ", useSelfClosingElement: true});
      responseBodyRawSize = responseBodyRawFinal.split(/\n/).length * 25;

    }
  }
  
  $(codeMirror_XML[codeMirror_XML_ids['ta_response']].getWrapperElement()).show();
  codeMirror_XML[codeMirror_XML_ids['ta_response']].setValue(responseBodyRawFinal);
  codeMirror_XML[codeMirror_XML_ids['ta_response']].refresh();
  codeMirror_XML[codeMirror_XML_ids['ta_response']].setSize("100%", responseBodyRawSize);

  $('.btn_download').show();
}  // end ajax_success_Fhir



 window.ajax_error = function(errMsg) {
  
  console.log("function ajax_error");
  console.log(errMsg);

  $('#ackDetails').show()
  
  //$('#responseText').html(errMsg.responseText);


  if (errMsg.responseJSON)  {

    if (errMsg.responseJSON.error || errMsg.responseJSON.error == "")  {

      $('#showDisplayName').text(errMsg.responseJSON.error).addClass('alert2').show();

    } else {

      $('#showDisplayName').text("Status 500, zie responsebericht").addClass('alert2').show();

      console.log("if (errMsg.responseJSON");
      //$('#ta_request').text(errMsg.responseJSON.request.args.payload);
      //$('#ta_response').text(errMsg.responseJSON.response);
    
      //request
      requestBodyRaw = errMsg.responseJSON.request.args.payload;
      requestBodyBeautified = new XmlBeautify().beautify(requestBodyRaw,{indent: "  ", useSelfClosingElement: true});

      $(codeMirror_XML[codeMirror_XML_ids['ta_request']].getWrapperElement()).show();
      codeMirror_XML[codeMirror_XML_ids['ta_request']].setValue(requestBodyRaw);
      codeMirror_XML[codeMirror_XML_ids['ta_request']].refresh();

      
      //response
      responseBodyRaw = errMsg.responseJSON.response;
      responseBodyBeautified = new XmlBeautify().beautify(responseBodyRaw,{indent: "  ", useSelfClosingElement: true});

      $(codeMirror_XML[codeMirror_XML_ids['ta_response']].getWrapperElement()).show();    
      codeMirror_XML[codeMirror_XML_ids['ta_response']].setValue(responseBodyRaw);
      codeMirror_XML[codeMirror_XML_ids['ta_response']].refresh();
    

      $('#request_validation').html(errMsg.responseJSON.validatedhtmlrequest);
      $('.btn_download').show();  
    }
  } else if (errMsg.responseText) {
    $('#showDisplayName').text(errMsg.responseText).addClass('alert2').show();
  }
} // end ajax_error



window.ajax_error_Fhir = function(errMsg) {
  
  console.log("function ajax_error");
  console.log(errMsg);

  $('#ackDetails').show()
  
  //$('#responseText').html(errMsg.responseText);

  if (errMsg.responseJSON)  {

    if (errMsg.responseJSON.error || errMsg.responseJSON.error == "")  {

      $('#showDisplayName').text(errMsg.responseJSON.error).addClass('alert2').show();

    } else {

      $('#showDisplayName').text("Status 500, zie responsebericht").addClass('alert2').show();

      console.log("if (errMsg.responseJSON");
      //$('#ta_request').text(errMsg.responseJSON.request.args.payload);
      //$('#ta_response').text(errMsg.responseJSON.response);
    
      //request
      requestBodyRaw = errMsg.responseJSON.request.args.payload;
      var requestBodyRawSize = (requestBodyRaw != null && requestBodyRaw.length > 0) ? requestBodyRaw.split(/\n/).length * 30 : 30;
      var requestBodyRawFinal = isJson(requestBodyRaw) ? JSON.stringify(JSON.parse(requestBodyRaw), null, ' ') : requestBodyRaw;

      $(codeMirror_XML[codeMirror_XML_ids['ta_request']].getWrapperElement()).show();
      codeMirror_XML[codeMirror_XML_ids['ta_request']].setValue(requestBodyRawFinal);
      codeMirror_XML[codeMirror_XML_ids['ta_request']].refresh();
      codeMirror_XML[codeMirror_XML_ids['ta_request']].setSize("100%", requestBodyRawSize);

      //response
      responseBodyRaw = errMsg.responseJSON.response;
      var responseBodyRawFinal = null;
      var responseBodyRawSize = 30;

      if (responseBodyRaw != null && responseBodyRaw.length > 0) {
        if (isJson(responseBodyRaw)){

          responseBodyRawFinal = JSON.stringify(JSON.parse(responseBodyRaw), null, ' ');
          responseBodyRawSize = responseBodyRaw.length * 3;

        } else {

          responseBodyRawFinal = new XmlBeautify().beautify(responseBodyRaw,{indent: "  ", useSelfClosingElement: true});
          responseBodyRawSize = responseBodyRawFinal.split(/\n/).length * 25;

        }
      }
      
      console.log(responseBodyRaw.length);
      $(codeMirror_XML[codeMirror_XML_ids['ta_response']].getWrapperElement()).show();
      codeMirror_XML[codeMirror_XML_ids['ta_response']].setValue(responseBodyRawFinal);
      codeMirror_XML[codeMirror_XML_ids['ta_response']].refresh();
      codeMirror_XML[codeMirror_XML_ids['ta_response']].setSize("100%", responseBodyRawSize);
    
      $('.btn_download').show();  
    }

  } else if (errMsg.responseText) {
    $('#showDisplayName').text(errMsg.responseText).addClass('alert2').show();
  }
} // end ajax_error_Fhir


window.ajax_complete = function(data, xhr){

  //console.log("ajax_complete");
  //console.log(data);
  //console.log(xhr);

  $('#loader').hide();
  $('#formButton').prop('disabled', false).children().text('Verstuur');
  // autoExpand($('#ta_response')[0]);   
  // autoExpand($('#ta_request')[0]); 
  
  

  if (data.responseJSON)  {

    if (data.responseJSON.error || data.responseJSON.error == "") {



    } else {
    
      // Request INFO
      var varRequestInfo = "";
      varRequestInfo += '<table class="table-req-resp"><thead><tr><th colspan="2">Info</th></tr></thead>';
      varRequestInfo += '<tbody><tr><td>Method</td><td>' + data.responseJSON.request.method + '</td></tr>';
      varRequestInfo += '<tr><td>URL</td><td>' + data.responseJSON.request.url + '</td></tr>';
      varRequestInfo += '</tbody></table>';
      $('#request_info').html(varRequestInfo).show();


      // Request Headers 
      var varHeaders = "";
      varHeaders += '<table class="table-req-resp"><thead><tr><th colspan="2">Headers</th></tr></thead><tbody>';
      $.each(data.responseJSON.request.headers, function(k,v) {        
          varHeaders += '<tr><td>' + k + '</td><td>' + v + '</td></tr>';
      });
      varHeaders += '</tbody></table>';
      $('#request_headers').html(varHeaders).show();


      // Response INFO
      var varResponseInfo = "";
      varResponseInfo += '<table class="table-req-resp"><thead><tr><th colspan="2">Info</th></tr></thead>';
      varResponseInfo += '<tbody><tr><td>HTTP Status code</td><td>' + data.responseJSON.code + '</td></tr>';
      varResponseInfo += '<tbody><tr><td>LSP Reactietijd</td><td>' + data.responseJSON.duration + '</td></tr>';
      // varResponseInfo += '<tr><td>LSP reactie eindtijd</td><td>' + data.responseJSON.end_time + '</td></tr>';
      varResponseInfo += '</tbody></table>';
      $('#response_info').html(varResponseInfo).show();


      // Response Headers 
      var varHeadersResponse = "";
      varHeadersResponse += '<table class="table-req-resp"><thead><tr><th colspan="2">Headers</th></tr></thead><tbody>';
      $.each(data.responseJSON.response_headers, function(k,v) {        
          varHeadersResponse += '<tr><td>' + k + '</td><td>' + v + '</td></tr>';
      });
      varHeadersResponse += '</tbody></table>';
      $('#response_headers').html(varHeadersResponse).show();


      // als usechannal = true, dan wordt de background job gebruikt icm actioncable
      if (!data.responseJSON.usechannel){
        $('#btn_view_xml_errors_request').show().text("Zie validatie").removeClass('btn-success');
      }
      
      $('#raw_xml_request, #raw_xml_response').show();
    }


  } // end if

}   // end ajax_complete

window.ajax_complete_Fhir = function(data, xhr){

  //console.log("ajax_complete");
  //console.log(data);
  //console.log(xhr);

  $('#loader').hide();
  $('#formButton').prop('disabled', false).children().text('Verstuur');  

  if (data.responseJSON)  {

    if (data.responseJSON.error || data.responseJSON.error == "") {



    } else {
    
      // Request INFO
      var varRequestInfo = "";
      varRequestInfo += '<table class="table-req-resp"><thead><tr><th colspan="2">Info</th></tr></thead>';
      varRequestInfo += '<tbody><tr><td class="table-req-resp-td">Method</td><td class="table-req-resp-td2">' + data.responseJSON.request.method + '</td></tr>';
      varRequestInfo += '<tr><td class="table-req-resp-td">URL</td><td class="table-req-resp-td2">' + data.responseJSON.request.url + '</td></tr>';
      varRequestInfo += '</tbody></table>';
      $('#request_info').html(varRequestInfo).show();


      // Request Headers 
      var varHeaders = "";
      varHeaders += '<table class="table-req-resp"><thead><tr><th colspan="2">Headers</th></tr></thead><tbody>';
      $.each(data.responseJSON.request.headers, function(k,v) {        
          varHeaders += '<tr><td class="table-req-resp-td">' + k + '</td><td class="table-req-resp-td2">' + v + '</td></tr>';
      });
      varHeaders += '</tbody></table>';
      $('#request_headers').html(varHeaders).show();


      // Response INFO
      var varResponseInfo = "";
      varResponseInfo += '<table class="table-req-resp"><thead><tr><th colspan="2">Info</th></tr></thead>';
      varResponseInfo += '<tbody><tr><td class="table-req-resp-td">HTTP Status code</td><td class="table-req-resp-td2">' + data.responseJSON.code + '</td></tr>';
      varResponseInfo += '<tbody><tr><td class="table-req-resp-td">LSP Reactietijd</td><td class="table-req-resp-td2">' + data.responseJSON.duration + '</td></tr>';
      // varResponseInfo += '<tr><td>LSP reactie eindtijd</td><td>' + data.responseJSON.end_time + '</td></tr>';
      varResponseInfo += '</tbody></table>';
      $('#response_info').html(varResponseInfo).show();


      // Response Headers 
      var varHeadersResponse = "";
      varHeadersResponse += '<table class="table-req-resp"><thead><tr><th colspan="2">Headers</th></tr></thead><tbody>';
      $.each(data.responseJSON.response_headers, function(k,v) {        
          varHeadersResponse += '<tr><td class="table-req-resp-td">' + k + '</td><td class="table-req-resp-td2">' + v + '</td></tr>';
      });
      varHeadersResponse += '</tbody></table>';
      $('#response_headers').html(varHeadersResponse).show();    

    }

  } 

}// end ajax_complete_Fhir




$(document).on('click', '#raw_xml_response', function(e){
    e.preventDefault();
    e.stopPropagation();
    if ($(this).hasClass('xmlraw')) {
        $(this).removeClass('xmlraw');
        $(this).text('See Raw');
        codeMirror_XML[codeMirror_XML_ids['ta_response']].setValue(responseBodyBeautified);
    } else {
        $(this).text('See Beautified');
        $(this).addClass('xmlraw');
        codeMirror_XML[codeMirror_XML_ids['ta_response']].setValue(responseBodyRaw);
    }
    codeMirror_XML[codeMirror_XML_ids['ta_response']].refresh();
});

$(document).on('click', '#raw_xml_request', function(e){
    e.preventDefault();
    e.stopPropagation();
    if ($(this).hasClass('xmlraw')) {
        $(this).removeClass('xmlraw');
        $(this).text('See Raw');
        codeMirror_XML[codeMirror_XML_ids['ta_request']].setValue(requestBodyBeautified);
    } else {
        $(this).text('See Beautified');
        $(this).addClass('xmlraw');
        codeMirror_XML[codeMirror_XML_ids['ta_request']].setValue(requestBodyRaw);
    }
    codeMirror_XML[codeMirror_XML_ids['ta_request']].refresh();
});

 window.OpvragenMetagegevensMetBeheerder_Burger = function(e, gegevenssoortInput){
  e.preventDefault();
  e.stopPropagation();

  var formBsn = document.getElementById("formBsn").value;
  var varOmgeving = document.getElementById("omgeving").value;
  var data = {"bsn":formBsn.trim(), "omgeving":varOmgeving };


    $.ajax({
      url: "/initialize_session", 
      type: "GET",
      beforeSend: function(request) {
        $('#loaderBsn').show();
      },
      success: function(data, textStatus, jqXHR)
      {
           

      $.ajax({
          type: "POST",
          url: "/OpvragenMetagegevensMetBeheerder_Burger_ajax",
          data: JSON.stringify(data),
          contentType: "application/json; charset=utf-8",
          dataType: "json",
          beforeSend: function(request){
            request.setRequestHeader("X-CSRF-Token", document.getElementsByName("csrf-token")[0].content);
            request.setRequestHeader( "bsn", formBsn.trim() );
            request.setRequestHeader( "omgeving", varOmgeving );

         },
          success: function(data2, status){
            data = data2.response;
            var element = document.getElementById("bsnResponse");
            var varPatientId = "";
            var varGegevenssoort = "";
            var varApplicatieId = "";
            var varUra = "";
            var varWijzigDatum = "";
            var varTrTdTotal = "";

            var varTrThNew = "<th>patientId</th><th>gegevensSoort</th><th>ApplicationId</th><th>URA</th><th>WijzigDatum</th> \n" ;
            var varTrTh = varTrThNew;

            if (isEmpty(data)) {
                var varTrTdTotal = '<tr>' + '<td  colspan="5">geen Patiënt gevonden</td>' +  "</tr>" ;
            } else {
              
                if (Object.keys(data["subject"]).length == 1) {

                    if ( "low" in data.subject.registrationProcess.effectiveTime ) {
                        var varEffTime = data.subject.registrationProcess.effectiveTime.low.value;
                    } else {
                        var varEffTime = "unk";
                    };

                    varPatientId = "<td>" +  data.subject.registrationProcess.subject2.ActReference.recordTarget.patient.id.extension + "</td>";
                    varGegevenssoort = "<td>" + data.subject.registrationProcess.code.code + "</td>";
                    varApplicatieId =  data.subject.registrationProcess.subject2.ActReference.custodian.assignedPerson.telecom.value;
                    varUra = "<td>" + data.subject.registrationProcess.subject2.ActReference.authorOrPerformer.assignedEntity.Organization.id.extension + "</td>";
                    varWijzigDatum = "<td>" + varEffTime + "</td>"; 
                    var varAppid =  "<td>" +  varApplicatieId.split(/[. ]+/).pop() + "</td>";
                    var varTrTd = "<tr>" + varPatientId.concat(varGegevenssoort, varAppid, varUra, varWijzigDatum) +  "</tr>" ;
                    varTrTdTotal += varTrTd;
                    
                } else {

                    const keys = Object.keys(data.subject);  
                    
                    for (const key of keys) { 
                        if ( "low" in data.subject[key].registrationProcess.effectiveTime ) {
                            var varEffTime = data.subject[key].registrationProcess.effectiveTime.low.value;
                        } else {
                            var varEffTime = "unk";
                        };

                        var varGegS = data.subject[key].registrationProcess.code.code
                  
                        varPatientId = "<td>" +  data.subject[key].registrationProcess.subject2.ActReference.recordTarget.patient.id.extension + "</td>";
                        varGegevenssoort = '<td>' + varGegS + "</td>";
                        varApplicatieId =  data.subject[key].registrationProcess.subject2.ActReference.custodian.assignedPerson.telecom.value;
                        varUra = "<td>" + data.subject[key].registrationProcess.subject2.ActReference.authorOrPerformer.assignedEntity.Organization.id.extension + "</td>";
                        varWijzigDatum = "<td>" + varEffTime + "</td>"; 
                        var varAppid =  "<td>" +  varApplicatieId.split(/[. ]+/).pop() + "</td>";
                        var varConcatTd = varPatientId.concat(varGegevenssoort, varAppid, varUra, varWijzigDatum);
                        var varTrTd = (varGegS == gegevenssoortInput) ? '<tr class="green">' + varConcatTd : '<tr>' + varConcatTd +  "</tr>" ;
                        varTrTdTotal += varTrTd;
                    } // End for (const key of keys) 

                } //End if Else Object.keys(data["subject"]).length == 1

            } // End If empty


            var varHtml = '<div class="table-responsive"> \n' +
                '<table id="table"> \n' +
                    '<thead id="av"> \n' +
                        "<tr> \n" + varTrTh + "</tr> \n" +
                    "</thead> \n" +
                    "<tbody> \n" +  varTrTdTotal + 
                    "</tbody> \n" +
                "</table>"  // End varHtml

            $(element).html(varHtml);  
                
            $('#tableBsnResponses tr').each(function(){
                $(this).find('td').each(function(){
                    //do your stuff, you can use $(this) to get current cell
                    if ( $(this).text() == "") {
                        $(this).closest('tr').addClass('tr_ok')
                    } else {
                        $(this).closest('tr').addClass('tr_ok')
                    }
                })
            });

            $('#queryResponseCode').fadeIn();

          }, //success
          error : function(jqXHR, textStatus, errorThrown) { 
            // if(jqXHR.status == 404 || errorThrown == 'Not Found') { 
                
            // }

            console.log(jqXHR);
            
            //$('#ackDetails').show();
            //$('#responseText').html(errMsg.responseText);


            // if (jqXHR.statusText)  {

            //   $('#showDisplayName').text(jqXHR.statusText).addClass('alert2').show();

            // } else {

            //   $('#showDisplayName').text("Er is iets fout gegaan").addClass('alert2').show();

            // }

            codeMirror_XML[codeMirror_XML_ids['ta_response_vwi']].setValue(new XmlBeautify().beautify(jqXHR.responseJSON.response,{indent: "  ", useSelfClosingElement: true}));
            codeMirror_XML[codeMirror_XML_ids['ta_response_vwi']].setSize("100%", "450px");    
            $(codeMirror_XML[codeMirror_XML_ids['ta_response_vwi']].getWrapperElement()).toggle();
            codeMirror_XML[codeMirror_XML_ids['ta_response_vwi']].refresh();


        },
          complete: function(jqXHR,status){
            
            $('#loaderBsn').hide();
        }
       }); // end Ajax

      },
      error: function(jqXHR, textStatus, errorThrown)
      {
        
        
      }
  });

  return false; 
}


window.codeMirror_XML = [];
window.codeMirror_XML_ids = {};


window.checkCodeMirrors = function(){

  console.log( "checkCodeMirrors length: " +  $('.textarea_xml').length );

  if ( $('.textarea_xml').length > 0 ) {

    var textareas = document.querySelectorAll(".textarea_xml");

    for (var i=0; i<textareas.length; i++) {

      var val = i.toString();
      codeMirror_XML_ids[textareas[i].id] = val;

      codeMirror_XML[i] = CodeMirror.fromTextArea(textareas[i], {
        mode: "xml",
        theme: hashToObject(gon.codemirror) === null ? 'monokai' : hashToObject(gon.codemirror)['theme'],
        //theme: "monokai",
        styleActiveLine: true,
        lineNumbers: true,
        height: 'auto',
        foldGutter: true,
        matchTags: {bothTags: true},
          gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
        extraKeys: {
          "Ctrl-Space": "autocomplete",
          "Ctrl-Q": function(cm){ cm.foldCode(cm.getCursor()); },
          "Ctrl-M": "toMatchingTag"
        }
      });

      $(codeMirror_XML[i].getWrapperElement()).hide();
      //codeMirror_XML[i].setSize("100%", "100%");

    } // end for loop

    // for (var i = 0; i < codeMirror_XML.length; i++) {
    //   codeMirror_XML_ids[codeMirror_XML[i].display.input.textarea.parentElement.parentElement.previousElementSibling.id] = [i];         
    // }
      
    // function getSelectedRange() {
    //   return { from: codeMirror_XML.getCursor(true), to: codeMirror_XML.getCursor(false) };
    // }

    // function autoFormatSelection() {
    //   var range = getSelectedRange();
    //   codeMirror_XML.autoFormatRange(range.from, range.to);
    // }

  } // end if $('#textarea_xml').length > 0
}

window.codeMirror_chat_XML = [];
window.codeMirror_chat_ids = {};


window.checkCodeMirrors_chat = function(){

  //console.log( "length: " +  $('.textarea_xml').length );

  if ( $('.textarea_chat_xml').length > 0 ) {

    var textareas = document.querySelectorAll(".textarea_chat_xml");

    for (var i=0; i<textareas.length; i++) {

      var val = i.toString();
      codeMirror_chat_ids[textareas[i].id] = val;

      codeMirror_chat_XML[i] = CodeMirror.fromTextArea(textareas[i], {
        mode: "xml",
        //theme: "monokai",//"elegant",
        theme: hashToObject(gon.codemirror)['theme'],
        styleActiveLine: true,
        lineNumbers: true,
        height: 'auto',
        foldGutter: true,
        matchTags: {bothTags: true},
          gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
        extraKeys: {
          "Ctrl-Space": "autocomplete",
          "Ctrl-Q": function(cm){ cm.foldCode(cm.getCursor()); },
          "Ctrl-M": "toMatchingTag"
        }
      });

      $(codeMirror_chat_XML[i].getWrapperElement()).hide();
      //codeMirror_XML[i].setSize("100%", "100%");

    } // end for loop

  } // end if $('#textarea_xml').length > 0
}

// Note that the name "myDropzone" is the camelized
// id of the form.


function hashToObject(hash) {
   // return JSON.parse(hash.replace('=>', ':'));
   return JSON.parse(hash);
}

var acc_panel_container = $('#acc_panel_container'),
    acc_left_panel = $('#acc_left_panel'),
    acc_right_panel = $('#acc_right_panel'),
    acc_drag_right = $('#acc_drag_right');
var AccPanelIsResizing = false;
var offsetRight;


$(document).on('mousedown', '#acc_drag_right', function (e) {

   AccPanelIsResizing = true;  
   acc_panel_container = $('#acc_panel_container');
   acc_left_panel = $('#acc_left_panel');
   acc_right_panel = $('#acc_right_panel');
   acc_drag_right = $('#acc_drag_right');  
})

$(document).on('mousemove', function (e) {  

  if (!AccPanelIsResizing) 
      return;

  offsetRight = acc_panel_container.width() - (e.clientX - acc_panel_container.offset().left);

  $('#acc_left_panel').css('right', offsetRight);
  $('#acc_right_panel').css('width', offsetRight);    

  return false;

 });

$(document).on('mouseup', '#acc_drag_right', function (e) {
     // stop resizing

   AccPanelIsResizing = false;
   var data = {"right":offsetRight}
   setAccPanel(data);

 });


$(document).on('click', '#userform_newcompany', function(){
   $('#js_userform_newcompany').toggle();
});

function setAccPanel(data){
    
    $.ajax({
        type: "POST",
        url: "/users/setaccpanels",
        data:  data ,
        dataType: "json",
        beforeSend: function(request){
           request.setRequestHeader( "X-CSRF-Token", document.getElementsByName("csrf-token")[0].content);
        },
        success: function(data, status){
           //console.log("success");
        },
        error: function(errMsg) {
           //console.log("error:");
           //console.log(errMsg);
        },
        complete: function(jqXHR,status){
           //console.log("complete:");
        }
     });
  }


$(document).ready(function(){
  
  // START WHILE LOOP
  get_uziCard();        

  // Start Zorg-ID ivm connectieprobleem
  //startZorgId();

  //Increment the idle time counter every minute.
  var idleInterval = setInterval(timerIncrement, 60000); // 1 minute


  //Zero the idle timer on mouse movement.
  $(this).mousemove(function (e) {
    idleTime = 0;
  });
  

  $(this).keypress(function (e) {
    idleTime = 0;
  });

  
  // DARK MODE

  $(document).on('click', '#darkmodeBtn', function() {
  //document.getElementById('darkmodeBtn').addEventListener('click',()=>{
    
    if (document.documentElement.getAttribute('data-bs-theme') == 'dark') {

        document.documentElement.setAttribute('data-bs-theme','light');

    } else {
        
        document.documentElement.setAttribute('data-bs-theme','dark');
        
    }

  });


  // if (dataBsTheme == "dark") {
  //   $("#darkMode").prop('checked', true);
  // } else {
  //   $("#darkMode").prop('checked', false);
  // }


  // async function checkOntvangenEnabled(datum){

  //   var start = new Date(datum).getTime();     
  //   var current = new Date().getTime();

  //   while( start > current ){
  //     current = new Date().getTime();
  //     await sleep(3000);
  //   }
  //   document.getElementById('div_setontvangentot').style.color = 'red';
  // } 

  
  // $(document).on('click', '#btn_setontvangentot', function() { 
  //     $.ajax({
  //           type: "POST",
  //           url: "/user/setontvangentot",
  //           //data: JSON.stringify(data),
  //           contentType: "application/json; charset=utf-8",
  //           dataType: "json",
  //           beforeSend: function(request){
  //             request.setRequestHeader("X-CSRF-Token", document.getElementsByName("csrf-token")[0].content);
  //             document.getElementById('div_setontvangentot').style.color = '#8bc34a';
  //           },
  //           success: function(data, status){
  //             console.log(data);
  //             document.getElementById('div_setontvangentot').innerHTML = "Enabled till: " + data.ontvangentot;

  //             checkOntvangenEnabled(data.datum);

  //           },
  //           error: function(errMsg) {
                 
  //           },
  //           complete: function(jqXHR,status){

  //           }
  //       });
  //   });

  // if ( $('#acc_left_panel').length > 0 ) {
  //   console.log($('#acc_left_panel').length > 0)
  //   const acc_left_panel = document.getElementById("acc_left_panel");
  //   changePanelWidth(acc_left_panel);
  // }

  // if ( $('#acc_right_panel').length > 0 ) {
  //   const acc_right_panel = document.getElementById("acc_right_panel");
  //   //changePanelWidth(acc_right_panel);
  //   changePanelWidth2();
  // }
  

  
    
  


$(document).on('change', '#change_style', function() {

   var styleId = $("#change_style option:selected")[0].value;
   console.log( styleId );

   var data = {"style_id":styleId};

   $.ajax({
      type: "POST",
      url: "/users/setstyle",
      data:  data ,
      dataType: "json",
      beforeSend: function(request){
         request.setRequestHeader( "X-CSRF-Token", document.getElementsByName("csrf-token")[0].content);

      },
      success: function(data, status){
         
         console.log("success");
         console.log(data);

         if (styleId = 1 || 8) {
          document.documentElement.setAttribute('data-bs-theme','light');
         } else {
          document.documentElement.setAttribute('data-bs-theme','dark');
         }
         location.reload();
      },
      error: function(errMsg) {
         console.log("error:");
         console.log(errMsg);

      },
      complete: function(jqXHR,status){
         console.log("complete:");
         console.log(status);

      }
   });
});


$(document).on('change', '#ketentest_rapportage_select', function(){

    var ktId = $("#ketentest_rapportage_select option:selected")[0].value;
    console.log( ktId );


    $.ajax({
        type: "GET",
        url: "/ketentest-rapportage/" + ktId,
        dataType: "json",
        beforeSend: function(request){
          //request.setRequestHeader('templateid', $("#template_aanpassen option:selected")[0].value)

        },
        success: function(data, status){
          console.log(data);

          $('#ketentest_rapportage').html(data.ketentest);

        },
        error: function(errMsg) {
    
        },
        complete: function(jqXHR,status){

        }
     });
  });


  window.GoBackWithRefresh = function() {
    window.history.back();
  } 


  $(document).on('click', '#wijzig-wrapper-btn', function(e){

    e.preventDefault();
    e.stopPropagation();

    console.log(e.currentTarget.innerText);

    if ( codeMirror_XML[codeMirror_XML_ids['ta-change-wrapper']].getValue().length > 0 ) {

      e.currentTarget.innerText = "HL7 Wrapper editor (optional)";

      codeMirror_XML[codeMirror_XML_ids['ta-change-wrapper']].setValue("");
      codeMirror_XML[codeMirror_XML_ids['ta-change-wrapper']].refresh();
      $(codeMirror_XML[codeMirror_XML_ids['ta-change-wrapper']].getWrapperElement()).hide();

    } else {

      e.currentTarget.innerText = "Close HL7 Wrapper editor (optional)";
      getWrapper(e.currentTarget.dataset.tmpl);

    }
    
  });


  $(document).on('click', '#jwt_custom_form_btn', function(e){

    e.preventDefault();
    e.stopPropagation();

    console.log(e.currentTarget.innerText);

    if ( codeMirror_XML[codeMirror_XML_ids['ta_jwt_header']].getValue().length > 0 ) {
      e.currentTarget.innerText = "Optioneel: change JWT";
      codeMirror_XML[codeMirror_XML_ids['ta_jwt_header']].setValue("");
      codeMirror_XML[codeMirror_XML_ids['ta_jwt_header']].refresh();
      $(codeMirror_XML[codeMirror_XML_ids['ta_jwt_header']].getWrapperElement()).hide();
    } else {
      e.currentTarget.innerText = "Close Optioneel: change JWT";
      getWrapper(e.currentTarget.dataset.tmpl);
    }

    if ( codeMirror_XML[codeMirror_XML_ids['ta_jwt_body']].getValue().length > 0 ) {
      codeMirror_XML[codeMirror_XML_ids['ta_jwt_body']].setValue("");
      codeMirror_XML[codeMirror_XML_ids['ta_jwt_body']].refresh();
      $(codeMirror_XML[codeMirror_XML_ids['ta_jwt_body']].getWrapperElement()).hide();
    } else {
      getWrapper(e.currentTarget.dataset.tmpl);
    }
    
  });


  window.getWrapper = function(templateFile) {

    console.log( "getWrapper function" );

    $.ajax({
        type: "GET",
        url: "/get_wrapper",
        dataType: "json",
        beforeSend: function(request){
          request.setRequestHeader('template', templateFile)
          // $('#handmatig_adres').prop('disabled', true)
          // $('#custom_address').hide();
        },
        success: function(data, status){
        console.log(data);
        var raw = data.template;  
        //var beautifiedXmlText = new XmlBeautify().beautify(data.template,{indent: "  ", useSelfClosingElement: true});
          
          $(codeMirror_XML[codeMirror_XML_ids['ta-change-wrapper']].getWrapperElement()).show();
          codeMirror_XML[codeMirror_XML_ids['ta-change-wrapper']].setValue(raw);
          codeMirror_XML[codeMirror_XML_ids['ta-change-wrapper']].refresh();
          codeMirror_XML[codeMirror_XML_ids['ta-change-wrapper']].setSize("100%", "450px");
          
          // $(codeMirror_XML[codeMirror_XML_ids['textarea_xml_clinicalDocument']].getWrapperElement()).show();
        },
        error: function(errMsg) {
    
        },
        complete: function(jqXHR,status){

         }
     });

  };

    window.getJWT = function(templateFile) {

    console.log( "getJWT function" );

    $.ajax({
        type: "GET",
        url: "/get_jwt",
        dataType: "json",
        beforeSend: function(request){
          //request.setRequestHeader('template', templateFile)
          // $('#handmatig_adres').prop('disabled', true)
          // $('#custom_address').hide();
        },
        success: function(data, status){
          console.log(data);
          
          $(codeMirror_XML[codeMirror_XML_ids['ta_jwt_header']].getWrapperElement()).show();
          codeMirror_XML[codeMirror_XML_ids['ta_jwt_header']].setValue(JSON.stringify(data.jwtheader, null, ' '));
          codeMirror_XML[codeMirror_XML_ids['ta_jwt_header']].refresh();
          codeMirror_XML[codeMirror_XML_ids['ta_jwt_header']].setSize("100%", "100px");

          $(codeMirror_XML[codeMirror_XML_ids['ta_jwt_body']].getWrapperElement()).show();
          codeMirror_XML[codeMirror_XML_ids['ta_jwt_body']].setValue(JSON.stringify(data.jwtbody, null, ' '));
          codeMirror_XML[codeMirror_XML_ids['ta_jwt_body']].refresh();
          codeMirror_XML[codeMirror_XML_ids['ta_jwt_body']].setSize("100%", "450px");
          
          // $(codeMirror_XML[codeMirror_XML_ids['textarea_xml_clinicalDocument']].getWrapperElement()).show();
        },
        error: function(errMsg) {
    
        },
        complete: function(jqXHR,status){

         }
     });

  };


  $(document).on('click', '.changeHeight-response-request', function(e) {

    var varId = $(this).parent().parent().find('.textarea_xml')[0].id;
    var varHeight = "450px";
    var varColor = "currentColor";

    console.log( e );

    //$(this).addClass('d-none');


    if ( !$(this).hasClass('clicked') ) {   
      // clicked = show UP icon
      varHeight = "100%";
      // varColor = "yellow";
      $(this).addClass("clicked");
      e.currentTarget.lastElementChild.attributes.d.value = 'M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z';
      
    } else {
      // clicked = show DOWN icon
      $(this).removeClass("clicked");
      e.currentTarget.lastElementChild.attributes.d.value = 'M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z';
      
    }

    e.currentTarget.attributes.fill.nodeValue = varColor;
    codeMirror_XML[codeMirror_XML_ids[varId]].setSize("100%", varHeight);

  });


  $(document).on('click', '#jwt_custom_form_btn', function() {

    checkCodeMirrors();

    $('#jwt_custom_form').toggle();

    var jwt_header = { 
                      typ: "att+JWT", 
                      kid: "ximulator50.test.aorta-zorg.nl"
                      };

    var jwt_body = {
                    jti: "uuid",
                    iat: 1656086556,
                    sub: "http://fhir.nl/fhir/NamingSystem/bsn|999900717",
                    iss: "https://vzvzsimulator.orangeclicks.nl/jwt",
                    role: "http://fhir.nl/fhir/NamingSystem/aorta-rolcode|P",
                    nbf: 0,
                    exp: 0,
                    aud: [
                      "urn:oid:2.16.840.1.113883.2.4.6.6.70000003"
                    ],
                    scope: "varScopeGegdienst50",
                    patient: "http://fhir.nl/fhir/NamingSystem/bsn|999900717",
                    client_id: "urn:oid:2.16.840.1.113883.2.4.6.6.1",
                    _vrb: {
                      _vrb_aud: "urn:oid:2.16.840.1.113883.2.4.6.6.1",
                      _vrb_ion: "VZVZ testteam",
                      _vrb_client_id: "urn:oid:2.16.840.1.113883.2.4.6.6.50000001"
                    },
                    ver: "1.1"
                  }

    $(codeMirror_XML[codeMirror_XML_ids['ta_jwt_header']].getWrapperElement()).show();
    codeMirror_XML[codeMirror_XML_ids['ta_jwt_header']].setValue(JSON.stringify(jwt_header, null, ' '));
    codeMirror_XML[codeMirror_XML_ids['ta_jwt_header']].refresh();
    codeMirror_XML[codeMirror_XML_ids['ta_jwt_header']].setSize("100%", "100px");

    $(codeMirror_XML[codeMirror_XML_ids['ta_jwt_body']].getWrapperElement()).show();
    codeMirror_XML[codeMirror_XML_ids['ta_jwt_body']].setValue(JSON.stringify(jwt_body, null, ' '));
    codeMirror_XML[codeMirror_XML_ids['ta_jwt_body']].refresh();
    codeMirror_XML[codeMirror_XML_ids['ta_jwt_body']].setSize("100%", "450px");

  });

  $(document).on('click', '#btn_view_xml_errors', function() {

    $('#response_validation').toggle();

    if ( $('#response_validation').css('display') == 'none' ) {
        $(this).text("Zie validatie");
    } else {
        $(this).text("Zie validatie (click again to close)");
    }

  });

  


  $(document).on('click', '#btn_view_xml_errors_request', function() {

    $('#request_validation').toggle();

    if ( $('#request_validation').css('display') == 'none' ) {
        $(this).text("Zie validatie");
    } else {
        $(this).text("Zie validatie (click again to close)");
    }

  });


  $(document).on('click', '.message_image_img', function() {

    var current_id = $(this).attr('id');
    var getChild = $(this).closest('.content-images').children();
    var img = "";
    var classie = "carousel-item";
    var indicator = "";
    var count = 0;

    getChild.each(function(i,v){
      
      if ( $(v).data('url') != undefined) {

        // images
        classie = current_id == $(v).attr('id') ? "carousel-item active" : "carousel-item";
        img = img + '<div class="' + classie + '"><img src="' + $(v).data('url') + '" class="d-block w-100" alt="' + $(v).data('url').split('/').at(-1) + '"/></div>'
      
        //indicators
        var indicatoractive = current_id == $(v).attr('id') ? 'class="active" aria-current="true"' : " ";
        indicator = indicator + '<button type="button" data-bs-target="#ketentestTestscriptCarousel" data-bs-slide-to="' + count +  '" ' + indicatoractive + ' aria-label="Slide ' + count + '"></button>'
        count = count + 1;
      }
      
    });

    $('#ketentestTestscriptCarousel-inner').html(img);
    $('#ketentestTestscriptCarousel-indicators').html(indicator);

    var myModalEl = document.querySelector('#ktTestscriptModal');
    var ktTestscriptModal = bootstrap.Modal.getOrCreateInstance(myModalEl);

    //const divIds = $('#parent-div > div').map((i, div) => div.id).get();
    //$('.droppie > div').map((i, div) => div.url).get();
    //var div = '<div class="carousel-item active"><img src="' +  + '" class="d-block w-100" alt="Wild Landscape"/></div>'
    
    ktTestscriptModal.show();

  });


  $(document).on('click', '.acc_message_image_img', function() {

    var current_id = $(this).attr('id');
    var getChild = $(this).closest('.content-images').children();
    var img = "";
    var classie = "carousel-item";
    var indicator = "";
    var count = 0;

    getChild.each(function(i,v){
      
      if ( $(v).data('url') != undefined) {

        // images
        classie = current_id == $(v).attr('id') ? "carousel-item active" : "carousel-item";
        img = img + '<div class="' + classie + '"><img src="' + $(v).data('url') + '" class="d-block w-100" alt="' + $(v).data('url').split('/').at(-1) + '"/></div>'
      
        //indicators
        var indicatoractive = current_id == $(v).attr('id') ? 'class="active" aria-current="true"' : " ";
        indicator = indicator + '<button type="button" data-bs-target="#acceptatieTestscriptCarousel" data-bs-slide-to="' + count +  '" ' + indicatoractive + ' aria-label="Slide ' + count + '"></button>'
        count = count + 1;
      }
      
    });

    $('#acceptatieTestscriptCarousel-inner').html(img);
    $('#acceptatieTestscriptCarousel-indicators').html(indicator);

    var myModalEl = document.querySelector('#accTestscriptModal');
    var accTestscriptModal = bootstrap.Modal.getOrCreateInstance(myModalEl);

    //const divIds = $('#parent-div > div').map((i, div) => div.id).get();
    //$('.droppie > div').map((i, div) => div.url).get();
    //var div = '<div class="carousel-item active"><img src="' +  + '" class="d-block w-100" alt="Wild Landscape"/></div>'
    
    accTestscriptModal.show();

  });

  
  // $(document).on('mouseover mouseleave','.ketentest-percent .card', function (e) {

  //   if( e.type === 'mouseover' ) {
      
  //     console.log("mouseenter");
  //     $('.ketentest-circle').attr("r", 70).attr("cx",70).attr("cy",70);

  //   } else {

  //     console.log("mouseleave");
  //     $('.ketentest-circle').attr("r", 40).attr("cx",40).attr("cy",40);
  //   }

  // });


  $(document).on('click', '.dz-details', function() {

    var current_id = $(this).closest('.dz-preview').attr('id');
    var getChild = $(this).closest('.dropzone').children();
    var img = "";
    var classie = "carousel-item";
    var indicator = "";
    var count = 0;

    getChild.each(function(i,v){
      
      if ( $(v).data('url') != undefined) {

        // images
        classie = current_id == $(v).attr('id') ? "carousel-item active" : "carousel-item";
        img = img + '<div class="' + classie + '"><img src="' + $(v).data('url') + '" class="d-block w-100" alt="' + $(v).data('url').split('/').at(-1) + '"/></div>'
      
        //indicators
        var indicatoractive = current_id == $(v).attr('id') ? 'class="active" aria-current="true"' : " ";
        indicator = indicator + '<button type="button" data-bs-target="#ketentestTestscriptCarousel" data-bs-slide-to="' + count +  '" ' + indicatoractive + ' aria-label="Slide ' + count + '"></button>'
        count = count + 1;
      }
      
    });



    $('#ketentestTestscriptCarousel-inner').html(img);
    $('#ketentestTestscriptCarousel-indicators').html(indicator);

    var myModalEl = document.querySelector('#ktTestscriptModal');
    var ktTestscriptModal = bootstrap.Modal.getOrCreateInstance(myModalEl);

    //const divIds = $('#parent-div > div').map((i, div) => div.id).get();
    //$('.droppie > div').map((i, div) => div.url).get();
    //var div = '<div class="carousel-item active"><img src="' +  + '" class="d-block w-100" alt="Wild Landscape"/></div>'
    
    ktTestscriptModal.show();

  });


  $(document).on('click', '.kt_testscript_teststap_img', function() {

    var current_id = $(this).attr('id');
    var getChild = $(this).closest('.testcses-physical-images').children();
    var img = "";
    var classie = "carousel-item";
    var indicator = "";
    var count = 0;

    console.log(current_id);
    console.log(getChild);


    getChild.each(function(i,v){
      
      if ( $(v).data('url') != undefined) {

        // images
        classie = current_id == $(v).attr('id') ? "carousel-item active" : "carousel-item";
        img = img + '<div class="' + classie + '"><img src="' + $(v).data('url') + '" class="d-block w-100" alt="' + $(v).data('url').split('/').at(-1) + '"/></div>'
      
        //indicators
        var indicatoractive = current_id == $(v).attr('id') ? 'class="active" aria-current="true"' : " ";
        indicator = indicator + '<button type="button" data-bs-target="#ketentestTestscriptCarousel" data-bs-slide-to="' + count +  '" ' + indicatoractive + ' aria-label="Slide ' + count + '"></button>'
        count = count + 1;
      }
      
    });

    

    $('#ketentestTestscriptCarousel-inner').html(img);
    $('#ketentestTestscriptCarousel-indicators').html(indicator);

    var myModalEl = document.querySelector('#ktTestscriptModal');
    var ktTestscriptModal = bootstrap.Modal.getOrCreateInstance(myModalEl);

    //const divIds = $('#parent-div > div').map((i, div) => div.id).get();
    //$('.droppie > div').map((i, div) => div.url).get();
    //var div = '<div class="carousel-item active"><img src="' +  + '" class="d-block w-100" alt="Wild Landscape"/></div>'
    
    ktTestscriptModal.show();

  });


$(document).on('click', '.acc_testscript_teststap_img', function() {

    var current_id = $(this).attr('id');
    var getChild = $(this).closest('.testcses-physical-images').children();
    var img = "";
    var classie = "carousel-item";
    var indicator = "";
    var count = 0;

    console.log(current_id);
    console.log(getChild);


    getChild.each(function(i,v){
      
      if ( $(v).data('url') != undefined) {

        // images
        classie = current_id == $(v).attr('id') ? "carousel-item active" : "carousel-item";
        img = img + '<div class="' + classie + '"><img src="' + $(v).data('url') + '" class="d-block w-100" alt="' + $(v).data('url').split('/').at(-1) + '"/></div>'
      
        //indicators
        var indicatoractive = current_id == $(v).attr('id') ? 'class="active" aria-current="true"' : " ";
        indicator = indicator + '<button type="button" data-bs-target="#acceptatieTestscriptCarousel" data-bs-slide-to="' + count +  '" ' + indicatoractive + ' aria-label="Slide ' + count + '"></button>'
        count = count + 1;
      }
      
    });

    $('#acceptatieTestscriptCarousel-inner').html(img);
    $('#acceptatieTestscriptCarousel-indicators').html(indicator);

    var myModalEl = document.querySelector('#accTestscriptModal');
    var accTestscriptModal = bootstrap.Modal.getOrCreateInstance(myModalEl);

    //const divIds = $('#parent-div > div').map((i, div) => div.id).get();
    //$('.droppie > div').map((i, div) => div.url).get();
    //var div = '<div class="carousel-item active"><img src="' +  + '" class="d-block w-100" alt="Wild Landscape"/></div>'
    
    accTestscriptModal.show();

  });

  // $('.btn').on('click', function(e) {
  //     console.log('Emiel');
  //     console.log(this);
  //     $('.btn').not(this).removeClass('active');    
  //     $(this).toggleClass('active');
  // });

  var varOmgeving = "XTO1";
  var formBsn = "900184590";
  var varAppId = '70000100';

  checkCodeMirrors();


  //const testcasesPhysicalDropzone = new Dropzone("#testcases-physical-dropzone", {

   Dropzone.options.testcasesPhysicalDropzone = {

    //headers: {"X-CSRF-Token":document.getElementsByName("csrf-token")[0].content },
    // The configuration we've talked about above
    autoProcessQueue: true,
    uploadMultiple: false,
    parallelUploads: 100,
    addRemoveLinks: true,
    maxFiles: 15,
    paramName: 'ketentest_image[image]',
    acceptedFiles: '.jpg, .jpeg, .png, .svg',
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    clickable: true,
    // params:{
    //   'authenticity_token':  document.getElementsByName("csrf-token")[0].content
    // },
    sending: function(file, xhr, formData) {

      // Append this to the request
      formData.append('authenticity_token', document.getElementsByName("csrf-token")[0].content);
      formData.append('ketentest_testscript_id', this.element.parentElement.parentElement.parentElement.attributes.ketentest_testscript_id.value);
      formData.append('testcases_physical_id', this.element.parentElement.parentElement.parentElement.attributes.testcases_physical_id.value);
    },
    removedfile: function(file) {
      // id = $(file.previewTemplate).find('.dz-remove').closest('[id]').attr('id');
      // var id = $(file.previewTemplate).find('.dz-remove').closest('[id]').attr('id');
      var id = file.previewTemplate.id;
      if (id) {
                          
        $.ajax({
            type: 'DELETE',
            url: '/ketentest_images/' + id +'.json',
            success: function(data) {
              console.log("succesvol verwijderd")
              //$(file.previewTemplate).fadeOut();
              file.previewElement.remove();
            },
            error: function(data) {
              // $(file.previewTemplate).fadeOut();
            }
        });  
      } else {
        file.previewElement.remove();
      }

    },

    // The setting up of the dropzone
    init: function() {
      var _this = this;

      //var ketentest_testscript_id = this.element.parentElement.parentElement.attributes.ketentest_testscript_id.value;
      //var testcases_physical_id = this.element.parentElement.parentElement.attributes.testcases_physical_id.value;
      //var ketentest_testscript_physicals_id = this.element.parentElement.parentElement.attributes.ketentest_testscript_physicals_id.value;

      // First change the button to actually tell Dropzone to process the queue.
      // this.element.querySelector("button[type=submit]").addEventListener("click", function(e) {
      //   // Make sure that the form isn't actually being sent.
      //   e.preventDefault();
      //   e.stopPropagation();
      //   myDropzone.processQueue();
      // });

      this.on("thumbnail", function (file, dataUrl) {
          // $(this).closest("dz-image-preview").attr('url', dataUrl);
          // console.log("====END===");
          //$('#media-upload-previews').append('<img src="' + dataUrl + '" width="56" height="56" alt="">');
      });
      this.on("success", function( file, response) {
        //$(file.previewElement).attr('data-url', response.image.url);
        //$(file.previewElement).prop('id', response.id);

      });
      // Listen to the sendingmultiple event. In this case, it's the sendingmultiple event instead
      // of the sending event because uploadMultiple is set to true.
      this.on("sendingmultiple", function() {
        // Gets triggered when the form is actually being sent.
        // Hide the success button or the complete form.
      });
      this.on("successmultiple", function(files, response) {
        // Gets triggered when the files have successfully been sent.
        // Redirect user or notify of success.
        console.log("successmultiple");
      });
      this.on("errormultiple", function(files, response) {
        // Gets triggered when there was an error sending the files.
        // Maybe show form again, and notify user of error
      });
      this.on("complete", function(file) {

        // $.get("current_user_roles", function(data, status){
          
        //     console.log(data);
        //     var roles = [];

        //     for (let x in data) {
        //       roles.push(data[x]['name']);
        //      console.log(data[x]['name']);
        //     }
        //     console.log(roles.includes('Read'));
        //     console.log(roles.includes('Readd'));

        //   });

        // if ( "<%= current_user.roles.exists?(['name LIKE ?', 'Read']) %>" ) {

        //   if ( "<%= current_user.roles.exists?(['name LIKE ?', 'Images']) %>" ) {
        //     console.log("Heeft Images role");
        //   } else {

        //     $(".dz-hidden-input").prop("disabled",true);
        //     $(".dz-remove").hide();
        //   }

        //   }

      });

      this.on("addedfile", function (file) {

        //$(mockFile.previewElement).attr('data-url', value.image.url);
        //$(mockFile.previewElement).prop('id', file.id);

        // $(this.previewsContainer).children().last('div').attr("data-url", "http://test")
        // $(this.previewsContainer).children().last('div').prop('id', '18');

      });


                // if ( parseInt(ketentest_testscript_physicals_id) > 0 ) {

        //   $.ajax({
        //     url: "/getimagesphysical/" + ketentest_testscript_physicals_id + '.json',
        //     dataType: "json",
        //     contentType: "application/json",
        //     method: "GET",
        //       beforeSend: function() { 
                                      
        //       },
        //       success: function(response) {   
               
        //         //console.log (response);

        //         // {
        //         //     "id": 18,
        //         //     "ketentest_testscript_physical_id": 11,
        //         //     "image": {
        //         //         "url": "/uploads/ketentest_image/image/18/zorg_ab.jpg"
        //         //     },
        //         //     "created_at": "2021-12-24T14:47:30.606+01:00",
        //         //     "updated_at": "2021-12-24T14:47:30.606+01:00"
        //         // }

        //         $.each(response, function (key, value) {

        //           var mockFile = { name: value.image.url.split("/")[5] , size: value.file_size };
        //           _this.displayExistingFile(mockFile, value.image.url);
        //           $(mockFile.previewElement).attr('data-url', value.image.url);
        //           $(mockFile.previewElement).prop('id', value.id);
                  
        //           if (value.read_status == true) {                
        //             $(mockFile.previewElement).find('.dz-hidden-input').prop("disabled",true);
        //             $(mockFile.previewElement).find(".dz-remove").hide();
        //           }
                  
        //         });

                
        //       }
        //   });  

        // } //end if 


      } //init
   
    }; // Dropzone.options.testcasesPhysicalDropzone



  // Ketentest Testscripts:
  // Modal
  
  // var ktTestscriptModal = new bootstrap.Modal(document.getElementById('ktTestscriptModal'), {
  //   keyboard: true
  // });


  // Carousel
  var ktTestscriptCarousel = document.querySelector('#ketentestTestscriptCarousel');
  var ketentestTestscriptCarousel = new bootstrap.Carousel(ktTestscriptCarousel, {
    interval: false
  });

  var accTestscriptCarousel = document.querySelector('#acceptatieTestscriptCarousel');
  var acceptatieTestscriptCarousel = new bootstrap.Carousel(accTestscriptCarousel, {
    interval: false
  });

  // $('#myModal').on('shown.bs.modal', function() {
  //  $('#myInput').trigger('focus')
  // })

  Dropzone.options.accTestcasesPhysicalDropzone = {
    autoProcessQueue: true,
    uploadMultiple: false,
    parallelUploads: 100,
    addRemoveLinks: true,
    maxFiles: 15,
    paramName: 'acceptatie_test_image[image]',
    acceptedFiles: '.jpg, .jpeg, .png, .svg',
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    clickable: true,
    sending: function(file, xhr, formData) {
      formData.append('authenticity_token', document.getElementsByName("csrf-token")[0].content);
      formData.append('acceptatie_test_id', this.element.parentElement.parentElement.parentElement.attributes.acceptatie_test_id.value);
      formData.append('acceptatie_testscript_id', this.element.parentElement.parentElement.parentElement.attributes.acceptatie_testscript_id.value);
      formData.append('testcases_physical_id', this.element.parentElement.parentElement.parentElement.attributes.testcases_physical_id.value);
    },
    removedfile: function(file) {
      // id = $(file.previewTemplate).find('.dz-remove').closest('[id]').attr('id');
      // var id = $(file.previewTemplate).find('.dz-remove').closest('[id]').attr('id');
      var id = file.previewTemplate.id;
      if (id) {
                          
        $.ajax({
            type: 'DELETE',
            url: '/acceptatie_test_images/' + id +'.json',
            success: function(data) {
              console.log("succesvol verwijderd")
              //$(file.previewTemplate).fadeOut();
              file.previewElement.remove();
            },
            error: function(data) {
              // $(file.previewTemplate).fadeOut();
            }
        });  
      } else {
        file.previewElement.remove();
      }

    },
    init: function() {
      var _this = this;
      
      this.on("thumbnail", function (file, dataUrl) {
          // $(this).closest("dz-image-preview").attr('url', dataUrl);
          // console.log("====END===");
          //$('#media-upload-previews').append('<img src="' + dataUrl + '" width="56" height="56" alt="">');
      });
      this.on("success", function( file, response) {
        //$(file.previewElement).attr('data-url', response.image.url);
        //$(file.previewElement).prop('id', response.id);

      });
      // Listen to the sendingmultiple event. In this case, it's the sendingmultiple event instead
      // of the sending event because uploadMultiple is set to true.
      this.on("sendingmultiple", function() {
        // Gets triggered when the form is actually being sent.
        // Hide the success button or the complete form.
      });
      this.on("successmultiple", function(files, response) {
        // Gets triggered when the files have successfully been sent.
        // Redirect user or notify of success.
        console.log("successmultiple");
      });
      this.on("errormultiple", function(files, response) {
        // Gets triggered when there was an error sending the files.
        // Maybe show form again, and notify user of error
      });
      this.on("complete", function(file) { });

      this.on("addedfile", function (file) { });

    } //init
   
  }; // Dropzone.options.AcceptatieTestcasesPhysicalDropzone


  // DROPZONE CHATBOX MESSAGES
  Dropzone.options.messageImageForm = { // The camelized version of the ID of the form element

    // The configuration we've talked about above
    autoProcessQueue: false,
    uploadMultiple: true,
    parallelUploads: 100,
    maxFiles: 100,
    addRemoveLinks: true,
    paramName: 'message_images',
    acceptedFiles: '.jpg, .jpeg, .png, .svg',
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    clickable: true,
    params:{
      'authenticity_token':  document.getElementsByName("csrf-token")[0].content
    },
    sending: function(file, xhr, formData) {

      // Append this to the request
      //formData.append('ketentest_testscript_id', this.element.parentElement.parentElement.attributes.ketentest_testscript_id.value);
      //formData.append('testcases_physical_id', this.element.parentElement.parentElement.attributes.testcases_physical_id.value);
    },
    removedfile: function(file) {

      //var id = file.previewTemplate.id;

      file.previewElement.remove();
     
    },
    success: function(response) { 
      console.log(response);
      this.removeAllFiles();
    },

    // The setting up of the dropzone
    init: function() {
      var myDropzone = this;

      this.on("addedfile", function (file) {

        //$(mockFile.previewElement).attr('data-url', value.image.url);
        //$(mockFile.previewElement).prop('id', file.id);

        // $(this.previewsContainer).children().last('div').attr("data-url", "http://test")
        // $(this.previewsContainer).children().last('div').prop('id', '18');
        document.getElementById('message_btn').disabled = false;

      });

      // First change the button to actually tell Dropzone to process the queue.
      this.element.querySelector("button[type=submit]").addEventListener("click", function(e) {
        // Make sure that the form isn't actually being sent.
        e.preventDefault();
        e.stopPropagation();
        if (myDropzone.files.length) {
          myDropzone.processQueue(); // upload files and submit the form
        } else {
          $('#message_image_form').submit(); // submit the form
        }
        myDropzone.processQueue();
      });

      // Listen to the sendingmultiple event. In this case, it's the sendingmultiple event instead
      // of the sending event because uploadMultiple is set to true.
      this.on("sendingmultiple", function() {
        // Gets triggered when the form is actually being sent.
        // Hide the success button or the complete form.
      });
      this.on("successmultiple", function(files, response) {
        // Gets triggered when the files have successfully been sent.
        // Redirect user or notify of success.

      });
      this.on("errormultiple", function(files, response) {
        // Gets triggered when there was an error sending the files.
        // Maybe show form again, and notify user of error
      });
    }
   
  }


  // DROPZONE KETENTEST CHATBOX MESSAGES
  Dropzone.options.ketentestMessageImageForm = {

    autoProcessQueue: false,
    uploadMultiple: true,
    parallelUploads: 100,
    maxFiles: 100,
    addRemoveLinks: true,
    paramName: 'message_images',
    acceptedFiles: '.jpg, .jpeg, .png, .svg',
    thumbnailWidth: 65,
    thumbnailHeight: 65,
    clickable: true,
    params:{
      'authenticity_token':  document.getElementsByName("csrf-token")[0].content
    },
    sending: function(file, xhr, formData) {

      // Append this to the request
      //formData.append('ketentest_testscript_id', this.element.parentElement.parentElement.attributes.ketentest_testscript_id.value);
      //formData.append('testcases_physical_id', this.element.parentElement.parentElement.attributes.testcases_physical_id.value);
    },
    removedfile: function(file) {

      //var id = file.previewTemplate.id;

      file.previewElement.remove();
     
    },
    success: function(response) { 
      //console.log(response);
      this.removeAllFiles();

    },

    // The setting up of the dropzone
    init: function() {
      var myDropzone = this;

      //console.log("ketentest_message_image_form dropzone launched")

      this.on("addedfile", function (file) {

        //$(mockFile.previewElement).attr('data-url', value.image.url);
        //$(mockFile.previewElement).prop('id', file.id);

        // $(this.previewsContainer).children().last('div').attr("data-url", "http://test")
        // $(this.previewsContainer).children().last('div').prop('id', '18');
        document.getElementById('message_btn').disabled = false;
        $('#upload-images-here').hide();
        $('#message_content').css("height","auto");

      });

      // First change the button to actually tell Dropzone to process the queue.
      this.element.querySelector("button[type=submit]").addEventListener("click", function(e) {
        // Make sure that the form isn't actually being sent.
        e.preventDefault();
        e.stopPropagation();
        if (myDropzone.files.length) {
          myDropzone.processQueue(); // upload files and submit the form
        } else {
          $('#ketentest_message_image_form').submit(); // submit the form
        }
        myDropzone.processQueue();
      });

      // Listen to the sendingmultiple event. In this case, it's the sendingmultiple event instead
      // of the sending event because uploadMultiple is set to true.
      this.on("sendingmultiple", function() {
        // Gets triggered when the form is actually being sent.
        // Hide the success button or the complete form.
      });
      this.on("successmultiple", function(files, response) {
        // Gets triggered when the files have successfully been sent.
        // Redirect user or notify of success.

      });
      this.on("errormultiple", function(files, response) {
        // Gets triggered when there was an error sending the files.
        // Maybe show form again, and notify user of error
      });
    }
  }


  
  $(document).on('change', '#template_aanpassen', function(){

    console.log( $("#template_aanpassen option:selected")[0].value );

    if (!$("#template_aanpassen option:selected")[0].value) {

      codeMirror_XML[codeMirror_XML_ids['textarea_xml_subject']].setValue("");
      codeMirror_XML[codeMirror_XML_ids['textarea_xml_subject']].refresh();
      $(codeMirror_XML[codeMirror_XML_ids['textarea_xml_subject']].getWrapperElement()).hide();
      return false;
    }


      $.ajax({
          type: "GET",
          url: "/get_template",
          dataType: "json",
          beforeSend: function(request){
            request.setRequestHeader('templateid', $("#template_aanpassen option:selected")[0].value)
            $('#handmatig_adres').prop('disabled', true);
            $('#custom_address').hide();
          },
          success: function(data, status){
          //console.log(data);

            var raw = data.template.xml;      
            $(codeMirror_XML[codeMirror_XML_ids['textarea_xml_subject']].getWrapperElement()).show();
            var beautifiedXmlText = new XmlBeautify().beautify(data.template.xml,{indent: "  ", useSelfClosingElement: true});
            codeMirror_XML[codeMirror_XML_ids['textarea_xml_subject']].setValue(raw);
            codeMirror_XML[codeMirror_XML_ids['textarea_xml_subject']].refresh();
            codeMirror_XML[codeMirror_XML_ids['textarea_xml_subject']].setSize("100%", "450px");
            // $(codeMirror_XML[codeMirror_XML_ids['textarea_xml_subject']].getWrapperElement()).show();
          },
          error: function(errMsg) {
      
          },
          complete: function(jqXHR,status){

          }
       });
    });

 $(document).on('click', '#zorgidinstalled', function(e) {
      
      $.ajax({
        url: "/zorgidinstalled", 
        type: "POST",
        beforeSend: function(request){
          request.setRequestHeader( "X-CSRF-Token", document.getElementsByName("csrf-token")[0].content);
        },
        success: function(data, textStatus, jqXHR)
        {
          
            location.reload();

        },
        error: function(jqXHR, textStatus, errorThrown)
        {
            
          
        },
        complete: function(){
        }
    });

  });

 


  $(document).on('click', '#tabs .btn', function(e) {      
      $('.btn').not(this).removeClass('active');    
      $(this).toggleClass('active');
  });

});   //end DOCUMENT READY

function changePanelWidth2(){
  console.log("changePanelWidth2");

  var isResizing = false;
  var lastDownX = 0;

    var container = $('#acc_container');
    var left = $('#acc_left_panel');
    var right = $('#acc_right_panel');
    var handle = $('#acc_drag');

    handle.on('mousedown', function (e) {
        isResizing = true;
        lastDownX = e.clientX;
    });

    $(document).on('mousemove', function (e) {
        // we don't want to do anything if we aren't resizing.
        if (!isResizing) 
            return;

        var offsetRight = container.width() - (e.clientX - container.offset().left);

        left.css('right', offsetRight);
        right.css('width', offsetRight);
    }).on('mouseup', function (e) {
        // stop resizing
        isResizing = false;
    });

}

function changePanelWidth(panel){

  console.log(panel)

    const BORDER_SIZE = 4;
    let m_pos;
    function resize(e){
      console.log(e);
      const dx = m_pos - e.x;
      m_pos = e.x;
      panel.style.width = (parseInt(getComputedStyle(panel, '').width) + dx) + "px";
    }

    panel.addEventListener("mousedown", function(e){
      console.log(e);
      if (e.offsetX < BORDER_SIZE) {
        m_pos = e.x;
        document.addEventListener("mousemove", resize, false);
      }
    }, false);

    document.addEventListener("mouseup", function(){
        document.removeEventListener("mousemove", resize, false);
    }, false);
  }


 window.detectIE = function() {
    try {
        var isIE = /*@cc_on!@*/false || !!document.documentMode;
        if ( ! isIE ) {
            // Fallback to UserAgent detection for IE
            if ( navigator.userAgent.indexOf("MSIE") > 0 ) {
                return true;    
            } else {
                return false;
            }
        }
        return true;
    } catch(e) {
        var error = e.toString();
        
    }
};


if (detectIE()) {
     
} else {
  console.log("No IE, GOOD browser Detected")
}


 window.sleep = function(ms) { return new Promise(resolve => setTimeout(resolve, ms)); }

 window.convertDate = function(d) {
  return ("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" +
    d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
}

window.sessionStorage.setItem('detachedopen', false);

async function get_uziCard(){

  while (true) {

    //console.log("counter: " + window.sessionStorage.getItem('userSignedOutCounter'));
    // console.log("get Uzicard info"); 
    //   console.log("gon.current_user:");
    //   console.log(gon.current_user);
    // console.log("userActiveTimeMs: ");
    // console.log(userActiveTimeMs);
    // console.log( " JSON.stringify(gon.current_user).length > 5 :")
    // console.log( JSON.stringify(gon.current_user).length > 5 )

    if (JSON.stringify(gon.current_user).length > 5) {
    
          $.ajax({
                url: "/uzicard", 
                type: "GET",
                beforeSend: function(request) {
                  request.setRequestHeader( "useractive", userActive);                  
                },
                success: function(data, textStatus, jqXHR)
                {
                  //console.log(data);
                  //window.sessionStorage.setItem('userSignedOutCounter', 0);
                  window.sessionStorage.setItem('sessionStatus', data.status);
                  window.sessionStorage.setItem('nonce', data.nonce);
                  window.sessionStorage.setItem('sessioninitialized', data.sessioninitialized);
                  window.sessionStorage.setItem('detachedopen', data.detachedopen);
                  window.sessionStorage.setItem('incominglastid', data.incominglastid);
                  window.sessionStorage.setItem('usepas', data.usepas);

                  $('#zorgidinfo').text("Zorg-ID status:  " + data.lastzorgidlines);
                  $('#sdkversion').text("SDK version: " + data.sdkversion);
                  $('#nonce').text("Nonce: " + data.nonce);
                  $('#sessioninitialized').text("Session initialized: " + data.sessioninitialized);
                  $('#status').text("Session status: " + data.status);
                  $('#detachedopen').text("Detached: " + data.detachedopen);
                  $('#uzicard').text("Status card: " + data.uzicard);
                  $('#changed').text("Card inserted/removed: " + convertDate( new Date( data.changed * 1000 ) ) );
                  $('#reason').text("Reason: " + data.reason);

                  if ( (!data.detachedopen) || (data.status == "Closed") || (data.parsedFields.length  == 0) ) {
                    //login zorgId button
                    $('#id_card' ).css( "color", "red" );
                    $('#login_zorgid_btn').css("display", "block"); 
                    $('#close_session_btn').css("display", "none");
                    $('#uzipas_btn').css("display", "none");
                    // $('#sign_btn').css("display", "none");
                    $('#parsedFields').text("parsedFields: " + false);
                    $('#uzipas_details').hide();        
                    window.sessionStorage.setItem('detachedopen', false);
                    return;
                  }

                  if ( data.status == "Opened" ) {
                    //Sign token button
                    $('#id_card' ).css( "color", "#75dd11" );
                    $('#login_zorgid_btn').css("display", "none"); 
                    $('#close_session_btn').css("display", "block");
                    $('#uzipas_btn').css("display", "block"); 
                  }


                },
                error: function(jqXHR, textStatus, errorThrown) 
                {

                  console.log('ERROR: you seem to have lost connection.');

                  // var cnt = parseInt(window.sessionStorage.getItem('userSignedOutCounter'));
                  // window.sessionStorage.setItem('userSignedOutCounter', cnt += 1 );

                  // if (window.sessionStorage.getItem('userSignedOutCounter') == "1") {
                  //   window.location.reload();
                  // }

                  $('#id_card' ).css( "color", "red" );
                  $('#login_zorgid_btn').css("display", "block"); 
                  $('#close_session_btn').css("display", "none");
                  $('#uzipas_btn').css("display", "none");
                  // $('#sign_btn').css("display", "none");
                  $('#uzipas_details').hide();        
                  window.sessionStorage.setItem('detachedopen', false);

                },
                complete: function(event) {
                  //console.log(event.status);
                  if ( parseInt(event.status) === 401) {
                    //console.log("401 GO TO HOMEPAGE");
                    gon.current_user = {};
                    window.location = '/'; 
                    // window.cable.subscriptions.consumer.disconnect();
                  }
                  
                }
                
            }); // end Ajax

       } // end if gon.current_user

    await sleep(userActiveTimeMs);
  } // end while loop
}   // end  get_uziCard()


 window.sign = function() {
      location.href = "/signed";
      // window.setTimeout(function () 
      // { 
      //     location.href = "signed" 
      // }, 0);
 }   

 window.close_session = function() {
  $.ajax({
      url: "/close_session", 
      type: "GET",
      beforeSend: function(request) {
        
      },
      success: function(data, textStatus, jqXHR)
      {
          console.log("Success close_session: " + textStatus); 
           //$( '#id_card' ).css( "background", "white" ).css( "color", "#75dd11" );
          // $( '#id_card' ).css( "color", "red" );
          // $('#login_zorgid_btn').css("display", "block"); 
          // $('#close_session_btn').css("display", "none");
          // $('#uzipas_btn').css("display", "none");
          // $('#uzipas_details').hide();
      },
      error: function(jqXHR, textStatus, errorThrown)
      {
          console.log("Error close_session"); 
          // $('#login_zorgid_btn').css("display", "none"); 
          // $('#close_session_btn').css("display", "block");
          // $('#uzipas_btn').css("display", "none");
      }
  });
}  


 window.open_session = function() {
  $.ajax({
        url: "/open_session", 
        type: "GET",
        beforeSend: function(request) {
          
        },
        success: function(data, textStatus, jqXHR)
        {
            $( '#id_card' ).css( "color", "#75dd11" );
            $('#login_zorgid_btn').css("display", "none"); 
            $('#close_session_btn').css("display", "block");
            $('#uzipas_btn').css("display", "block");
        },
        error: function(jqXHR, textStatus, errorThrown)
        {
            $('#id_card' ).css( "color", "red" );
            $('#login_zorgid_btn').css("display", "block"); 
            $('#close_session_btn').css("display", "none");
            $('#uzipas_btn').css("display", "none");
          
        },
        complete: function(){
          $('#loaderUziPas').hide();
        }
    });
}


